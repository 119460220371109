// @flow
import { Map } from 'immutable';
import { makeActionTypes } from 'app/state/duckFactory';

const mountPoint = 'REMOTE_META';
const actionTypes = makeActionTypes(mountPoint, ['REQUEST_START', 'REQUEST_SUCCESS', 'REQUEST_FAILED', 'VALIDATION_START', 'VALIDATION_FINISH']);


type RemoteMetaKeyType = string;

type RemoteMetaType = {
    type: 'request',
    timestamp: number,
    inProgress: boolean,
    hash: string
} | {
    type: 'validation',
    inProgress: boolean
};

type RemoteMetaStateType = Map<RemoteMetaKeyType, RemoteMetaType>;

const initialState: RemoteMetaStateType = new Map();

export default {
    mountPoint,
    actionTypes,
    reducer(state: RemoteMetaStateType = initialState, action: *) {
        switch (action.type) {
            case actionTypes.REQUEST_START:
                return state.withMutations((state) => {
                    state.setIn([action.key, 'inProgress'], true);
                    state.setIn([action.key, 'hash'], action.hash);
                    state.setIn([action.key, 'type'], 'request');
                });
            case actionTypes.REQUEST_SUCCESS:
                return state.withMutations((state) => {
                    state.setIn([action.key, 'timestamp'], (new Date).getTime());
                    state.setIn([action.key, 'inProgress'], false);
                });
            case actionTypes.REQUEST_FAILED:
                return state.withMutations((state) => {
                    state.setIn([action.key, 'inProgress'], false);
                });
            case actionTypes.VALIDATION_START:
                return state.withMutations((state) => {
                    state.setIn([action.key, 'inProgress'], true);
                    state.setIn([action.key, 'type'], 'validation');
                });
            case actionTypes.VALIDATION_FINISH:
                return state.withMutations((state) => {
                    state.setIn([action.key, 'inProgress'], false);
                });
            default:
                return state;
        }
    },
    actionCreators: {
        setRequestStart(key: RemoteMetaKeyType, hash: string) {
            return { type: actionTypes.REQUEST_START, key, hash };
        },
        setRequestSuccess(key: RemoteMetaKeyType) {
            return { type: actionTypes.REQUEST_SUCCESS, key };
        },
        setRequestFailed(key: RemoteMetaKeyType) {
            return { type: actionTypes.REQUEST_FAILED, key };
        },
        setValidationStart(key: RemoteMetaKeyType) {
            return { type: actionTypes.VALIDATION_START, key };
        },
        setValidationFinished(key: RemoteMetaKeyType) {
            return { type: actionTypes.VALIDATION_FINISH, key };
        },
    },
    selectors: {
        getHash(state: *, key: RemoteMetaKeyType): number {
            return state[mountPoint].getIn([key, 'hash']) || '';
        },
        getTimestamp(state: *, key: RemoteMetaKeyType): number {
            return state[mountPoint].getIn([key, 'timestamp']) || 0;
        },
        getInProgress(state: *, key: RemoteMetaKeyType): boolean {
            return state[mountPoint].getIn([key, 'inProgress']) || false;
        },
        hasPendingRquests(state: *): boolean {
            return state[mountPoint].reduce((acc, value) => {
                if (value.get('type') === 'request') {
                    acc = acc || value.get('inProgress');
                }
                return acc;
            }, false);
        },
        hasPendingValidations(state: *): boolean {
            return state[mountPoint].reduce((acc, value) => {
                if (value.get('type') === 'validation') {
                    acc = acc || value.get('inProgress');
                }
                return acc;
            }, false);
        }
    }
};

