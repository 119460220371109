// @flow
import * as React from 'react';
import { formValues } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import componentMount from 'app/hoc/componentMount';
import { country } from 'app/default/field/name';
import { entityTermsSelector, mapDispatchToProps } from 'app/default/field/TermsAndConditionsField';

const TermsAndConditionsContent = ({ content }: { content: string }) => (
    <div
        className="content" dangerouslySetInnerHTML={{
            __html: content
        }}
    />
);

TermsAndConditionsContent.propTypes = {
    content: PropTypes.string.isRequired
};

const ConnectedTermsAndConditionsContent = connect(
    (state) => ({
        content: entityTermsSelector(state).terms_and_conditions || '',
    }),
    mapDispatchToProps
)(componentMount(TermsAndConditionsContent));

export default formValues({ countryIso: country[0] })(ConnectedTermsAndConditionsContent);
