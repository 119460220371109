// @flow
import * as React from 'react';
import PropTypes from 'prop-types';
import getDisplayName from 'react-display-name';
import { Loader, Column, Stepper as StepperControl, withStyles } from '@phg/stilo-toolbox/components';
import { connect } from 'react-redux';
import remoteMeta from 'app/state/ducks/remoteMeta';
import { STEPPER_SET_STEP } from 'app/state/reducers/application';


export type StepperProps = {
    children: Array<React.Element<*>>, 
    container: React.ComponentType<*>,
    classes: {
        Container: string,
        LoadingOverlay: string
    },
    loading: boolean,
    step: number,
    setStep: (number) => void
};

export type StepperState = {
    count: number,
    total: number
};

const stepperContextType = {
    _stepper: PropTypes.shape({
        next: PropTypes.func.isRequired,
        prev: PropTypes.func.isRequired,
        first: PropTypes.func.isRequired,
        current: PropTypes.func.isRequired
    }).isRequired
};

const makeStyle = () => ({
    Container: {
        position: 'relative'
    },
    LoadingOverlay: {
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        position: 'absolute',
        width: '100%',
        top: 0,
        left: 0,
        height: '100%'
    }
});

class Stepper extends React.Component<StepperProps, StepperState> {
    static defaultProps = {
        container: Column
    };
    
    static childContextTypes = stepperContextType;

    static buildContext = (instance: Stepper) => ({
        _stepper: {
            next() {
                if (instance.state.total > instance.props.step) {
                    instance.props.setStep(instance.props.step + 1);
                }
            },
            prev() {
                if (instance.props.step > 0) {
                    instance.props.setStep(instance.props.step - 1);
                }
            },
            first() {
                if (instance.props.step > 0) {
                    instance.props.setStep(0);
                }
            },
            current() {
                return instance.props.step;
            }
        }
    });

    static countSteps(steps: Array<React.Element<*>>) {
        return steps.reduce((count, step) => {
            if (step.props.notCounted) {
                return count;
            }

            return count + 1;
        }, 0);
    }
    
    state = {
        count: Stepper.countSteps(this.props.children),
        total: this.props.children.length
    };
    
    componentWillReceiveProps(props: StepperProps) {
        if (this.props.children !== props.children) {
            this.setState({
                count: Stepper.countSteps(props.children),
                total: props.children.length
            });
        }
    }

    _context = Stepper.buildContext(this);
    
    getChildContext = () => this._context;
    
    render() {
        const Container = this.props.container;
        const count = Stepper.countSteps(this.props.children);
        
        return (
            <Container addCls={this.props.classes.Container} className={this.props.classes.Container}>
                <StepperControl totalSteps={count} currentStep={this.props.step}/>
                {this.props.children[this.props.step]}
                {this.props.loading && (<div className={this.props.classes.LoadingOverlay}><Loader /></div>)}
            </Container>
        );
    }
}

const ConnectedStepper = connect(
    (state) => ({
        loading: remoteMeta.selectors.hasPendingRquests(state),
        step: state.application.currentStep // fixme
    }),
    (dispatch) => ({
        setStep(step) {
            dispatch({ type: STEPPER_SET_STEP, payload: step });
        }
    })
)(Stepper);

export default withStyles(makeStyle)(ConnectedStepper);

export const withStepperControl = (Component: React.ComponentType<*>) => {
    // eslint-disable-next-line react/no-multi-comp
    return class extends React.Component<*> {
        static displayName = `WithStepperControl(${getDisplayName(Component)})`;
        static contextTypes = stepperContextType;

        render() {
            return <Component {...this.props} stepperControl={this.context._stepper} />;
        }
    };
};

