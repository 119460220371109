// @flow
import createField from 'app/hoc/createField';
import {
    notEmptyValidate,
    passwordValidate,
    sameFieldValidate
} from 'app/validator/sync';
import l from 'app/Translate';
import { Input } from './base';
import { password } from './name';

const PasswordField = createField(password, () => ({
    id: 'Password',
    type: 'password',
    label: l('Password'),
    validate: passwordValidate,
    warn: notEmptyValidate
}))(Input);

export default PasswordField;

export const PasswordConfirmField = createField('__confirm_password__', () => ({
    id: 'ConfirmPassword',
    type: 'password',
    label: l('Confirm Password'),
    validate: sameFieldValidate('password', l('Ensure passwords match')),
    warn: notEmptyValidate
}))(Input);
