// @flow
import * as React from 'react';
import PropTypes from 'prop-types';

import ErrorsComponent from 'app/components/Form/errorsComponent';

const withFeedback = (Wrapped: React.ComponentType<*>, ErrorDisplayComponent: React.ComponentType<*> = ErrorsComponent) => {
    return class FieldRowWrapper extends React.Component<*> {
        static propTypes = {
            id: PropTypes.string.isRequired,
            label: PropTypes.node,
            tooltip: PropTypes.string,
            meta: PropTypes.object.isRequired,
            containerStyle: PropTypes.object,
            containerClassName: PropTypes.string,
            innerContainerStyle: PropTypes.object,
            innerContainerClassName: PropTypes.string
        };

        render() {
            const {
                containerStyle,
                containerClassName,
                innerContainerStyle,
                innerContainerClassName,
                ...props
            } = this.props;

            const {
                meta: {touched, error, warning, networkError},
                id = '',
            } = props;


            return (
                <div className="row">
                    <div id={id + 'Container'} style={containerStyle} className={containerClassName}>
                        <div style={innerContainerStyle} className={innerContainerClassName}>
                            <div
                                className={'col-xs-12'
                                + (touched && error ? ' errorField' : '')
                                + (touched && warning ? ' warningField' : '')}
                            >
                                <Wrapped id="unknown" {...props} />
                            </div>
                        </div>
                        <ErrorDisplayComponent
                            {...props}
                            touched={touched}
                            error={props.error || error}
                            warning={warning}
                            id={id}
                            networkError={networkError}
                        />
                    </div>
                </div>
            );
        }
    };
};

export default withFeedback;