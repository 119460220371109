// @flow
import getIn from 'redux-form/lib/structure/plain/getIn';
import setIn from 'redux-form/lib/structure/plain/setIn';
import { userName, emailAddress } from 'app/default/field/name';
import { get } from 'app/connect';
import remoteMeta from 'app/state/ducks/remoteMeta';

import type { FormProps } from 'redux-form/lib/types';
import type { Dispatch } from 'redux'; 

type ImprovedFromProps = FormProps & {
    asyncErrors: {[string]: ?string}
};

const createResponseCreator = (res, rej, errors) => (field, error) => {
    if (field && error) {
        return rej(setIn(errors, field, error));
    }
    if (Object.values(errors).length) {
        return rej(errors);
    }
    return res();
};


let validateEmail = false;

const createAsyncValidate = (_: typeof get) => 
    (values: {}, dispatch: Dispatch<*>, props: ImprovedFromProps, blurredField: string) => {
    
        const errors = props.asyncErrors || {};

        return new Promise((res, rej) => {
            const responseCreator = createResponseCreator(res, rej, errors);
            const usernameValue = getIn(values, userName[0]);
            const emailValue = getIn(values, emailAddress);
            
            if (blurredField === userName[0] && usernameValue) {
                dispatch(remoteMeta.actionCreators.setValidationStart('validate:username:' + usernameValue));
                delete errors[userName[0]];
                dispatch(remoteMeta.actionCreators.setValidationFinished('validate:username:' + usernameValue));
                return responseCreator();
            } else if (validateEmail && blurredField === emailAddress && emailValue) {
                dispatch(remoteMeta.actionCreators.setValidationStart('validate:email:' + emailValue));
                delete errors[emailAddress];
                dispatch(remoteMeta.actionCreators.setValidationFinished('validate:email:' + emailValue));
                return responseCreator();
            } else {
                return responseCreator();
            }
        });
    };

const asyncValidate = createAsyncValidate(get);

const asyncValidateFields = [
    ...userName
];

const enableEmailAsyncValidation = () => {
    if (!validateEmail) {
        asyncValidateFields.push(emailAddress);
        validateEmail = true;
    }
};

export {
    asyncValidate as default,
    createAsyncValidate,
    asyncValidateFields,
    enableEmailAsyncValidation
};