// @flow
import * as React from 'react';
import l from 'app/Translate';
import Overlay from './Modal';

type ModalTriggerProps = {
    id?: string,
    children: React.Element<React.ComponentType<{ onClick: (...*) => * }>>,
    title: string,
    content: string
};

export default
class ModalTrigger extends React.Component<ModalTriggerProps, { open: boolean }> {
    state = {
        open: false
    };

    constructor(...args: *) {
        super(...args);

        // $FlowIgnore
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose = () => this.setState({ open: false });

    onClick = (...args: Array<*>) => {
        this.setState({ open: true });
        if (this.props.children.props.onClick) {
            this.props.children.props.onClick(...args);
        }
    };

    render() {
        const trigger = React.Children.only(this.props.children);
        return (
            <React.Fragment>
                {React.cloneElement(trigger, { onClick: this.onClick })}
                <Overlay
                    id={this.props.id}
                    title={this.props.title}
                    isOpen={this.state.open}
                    onClickClose={this.handleClose}
                    content={this.props.content.replace(/\n/g, '<br />')}
                    closeLabel={l('Close')}
                />
            </React.Fragment>
        );
    }
}
