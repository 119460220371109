export default ({globalVariables={}}={}) => {
    const errorColor = globalVariables['$error-text'];
    const warningColor = globalVariables['$color-warning'];

    const btnColor1 = globalVariables['$btnColor1'];
    const btnColor2 = globalVariables['$btnColor2'];

    const enabledSubmitBtn = globalVariables['$enabledSubmitBtn'];
    const disabledSubmitBtn = globalVariables['$disabledSubmitBtn'];
    const disabledSubmitBtnOpacity = globalVariables['$disabledSubmitBtnOpacity']||1;
    const enabledSubmitBtnOpacity = globalVariables['$enabledSubmitBtnOpacity']||1;

    const topBarHeight = globalVariables['$topBarHeight'];
    const topBarColor = globalVariables['$navbar-background'];
    const signUpFormWidth = globalVariables['$signUpFormWidth'];

    const logoImg = globalVariables['$logoImg'];
    const logoWidth = globalVariables['$logoWidth'];
    const logoHeight = globalVariables['$logoHeight'];
    const logoMargin = globalVariables['$logoMargin'];

    const footerHeight = globalVariables['$footerHeight']||'0px';

    const bottomBarHeight = globalVariables['$bottomBarHeight'];
    const bottomBarColor = globalVariables['$navbar-background'];

    return {
        Onboarding: {
            flex: 1,
            display: 'flex',
            msFlexPreferredSize: 'auto',
            '& *': {
                fontFamily: 'Arial, Helvetica, sans-serif'
            },
            '&.panel.panel-default .panel-heading': {
                fontFamily: 'Open Sans',
                fontSize: '1.5em',
                fontWeight: 100,
                textAlign: 'center',
                background: 'none',
                border: 'none',
                backgroundColor:'#fff',
                borderTopLeftRadius:4,
                borderTopRightRadius:4
            },
            '& .column.panel-default': {
                paddingRight: '20px',
                paddingLeft: '20px',
            },
            '&.panel.panel-default,#onboarding-panel': {
                flex: '0',
                margin: '0 auto',
                boxShadow: 'none',
                border: 'none',
            },
            '&.panel.panel-default div.panel-body': {
                minWidth: signUpFormWidth,
                backgroundColor:'#fff',
                borderBottomLeftRadius:4,
                borderBottomRightRadius:4,
                paddingBottom:10
            },
            '& input.form-control, .dropdown.btn-group': {
                borderRadius: '5px',
                marginBottom: 10
            },
            '& label': {
                textAlign: 'left',
                float: 'left',
                paddingTop: 7,
                marginBottom: '0'
            },
            '& .form-horizontal': {
                '& .form-group': {
                    marginTop: 0,
                    marginLeft: 0,
                    marginRight: 0,
                    '& .terms-container': {
                        '& label': {
                            lineHeight: 'inherit',
                            paddingLeft: 5,
                            paddingRight: 3
                        }
                    }
                },
            },
            '& .form-group > div': {
                '& .stilo-input-horizontal': {
                    display: 'block',

                }
            },
            '& .buttons': {
                width: '100%',
                float: 'right',
                '& a': {
                    position: 'relative',
                    left: -11,
                    lineHeight: '35px',
                    '& span[class^=StiloGlyph]': {
                        fontSize: 27,
                        position: 'relative',
                        top: 4
                    }
                },
                '& button': {
                    float: 'right',
                }
            },
            '& button.btn.btn-success': {
                backgroundColor: enabledSubmitBtn,
                borderColor: enabledSubmitBtn,
                opacity: enabledSubmitBtnOpacity,
                lineHeight: '25px',
                '&:disabled,&.disabled': {
                    backgroundColor: disabledSubmitBtn,
                    borderColor: disabledSubmitBtn,
                    opacity: disabledSubmitBtnOpacity
                }
            },
            '& .dropdown.btn-group': {
                marginBottom: '16px',
                width: '100%',
                '& button': {
                    border: '1px #dcdcdc solid',
                    width: '100%',
                    background: '-webkit-linear-gradient(top, ' + btnColor1 + ' 0%,' + btnColor2 + ' 100%)',
                    fallbacks: [
                        {background: '' + btnColor1 + ''},
                        {background: '-moz-linear-gradient(top, ' + btnColor1 + ' 0%, ' + btnColor2 + ' 100%)'},
                        {background: 'linear-gradient(to bottom, ' + btnColor1 + ' 0%,' + btnColor2 + ' 100%)'}
                    ],
                    filter: 'progid:DXImageTransform.Microsoft.gradient(' +
                    `startColorstr='${btnColor1}', endColorstr='${btnColor2}',GradientType=0` +
                    ')',
                    textAlign: 'left',
                    '& span': {
                        position: 'absolute',
                        top: '50%',
                        right: 20
                    }
                }
            },
            '& form': {
                marginTop: '40px',
            },
            '& .row': {
                marginRight: 0,
                marginLeft: 0,
                '& div': {
                    padding: 0,
                },
                '& label': {
                    paddingRight: '10px',
                    fontWeight: '100'
                },
                '& .error': {
                    left: '50%',
                    textAlign: 'right',
                    '& span': {
                        display: 'inline-block',
                        textAlign: 'right',

                        paddingBottom: 11,
                        paddingLeft: 5,
                        paddingRight: 5,
                        '&.errorText': {
                            color: errorColor
                        },
                        '&.warningText': {
                            color: warningColor
                        }
                    }
                },
                '& .errorField input': {
                    border: '1px ' + errorColor + ' solid'
                },
                '& .warningField input': {
                    border: '1px ' + warningColor + ' solid'
                }
            },
            '& .row div.info-box': {
                padding: [5, 10]
            }
        },
        Topbar: {
            height: topBarHeight,
            backgroundColor: topBarColor,
            padding: '12px 20px',
            '& .logo': {
                background: 'url(' + logoImg + ') no-repeat',
                backgroundSize: 'contain',
                height: logoHeight,
                width: logoWidth,
                margin: logoMargin,
                float: 'left'
            },
            '& .children': {
                float: 'right',
                '& button#LanguageSelector': {
                    width: 214
                },
                '& .dropdown': {
                    marginBottom: 0
                }
            }
        },
        SignupForm: {
            height: '100%',
            overflow: 'auto',
            '& .content-panel': {
                display: 'flex',
                alignItems: 'center',
                minHeight: `calc(100% - ${topBarHeight} - ${footerHeight})`,
                padding: 10,
                fallbacks: [
                    {display: '-webkit-box'},
                    {display: '-moz-box'},
                    {display: '-ms-flexbox'},
                    {display: '-webkit-flex'},
                ],
                flexDirection: 'column',
                justifyContent: 'center',
                '&>div': {
                    flex: 'initial',
                }
            }
        },
        Bottombar: {
            height: bottomBarHeight,
            backgroundColor: bottomBarColor,
            padding: '12px 20px'
        },
    };
};
