// @flow
import createField from 'app/hoc/createField';
import {
    alphaDashValidate,
    maxLengthValidate,
    minLengthValidate,
    notEmptyValidate
} from 'app/validator/sync';
import l from 'app/Translate';
import { UsernameInput } from './base';
import { userName } from './name';

// Create fields to be used on the form
const UsernameField = createField(userName, () => ({
    id: 'Username',
    type: 'text',
    warn: notEmptyValidate,
    label: l('Username'),
    validate: [minLengthValidate(2), maxLengthValidate(32), alphaDashValidate],
    tooltip: l('The advertiser needs to be able to uniquely identify you in the system. ' +
        'Please don\'t use the advertiser\'s name in your username. You could use your company name or full ' +
        'name as your username.')
}))(UsernameInput); // todo incorporate async validator! (at the moment it is injected to the form from the top)

export default UsernameField;
