// @flow
import { composeDuck } from 'app/state/duckFactory';
import type { DuckConfigType } from 'app/state/duckFactory';

const baseApiDuckTemplate = {
    actionTypesNames: [ 'SUCCESS', 'ERROR', 'FETCH_ASYNC' ],
    actionCreatorsFactory: ({ SUCCESS, ERROR }) => ({
        successAction: (data) => ({
            type: SUCCESS,
            payload: data
        }),

        errorAction: (errors) => ({
            type: ERROR,
            payload: errors
        }),
    }),
    selectors: {
        selectErrors: (state) => state.errors,
        selectLoading: (state) => state.loading,
        selectData: (state) => state.data
    },
    reducersFactory: ({ SUCCESS, ERROR, FETCH_ASYNC }) => ({
        [ SUCCESS ]: (state, action) => ({
            ...state,
            data: action.payload,
            errors: null,
            loading: false
        }),
        [ ERROR ]: (state, action) => ({
            ...state,
            errors: action.payload,
            loading: false
        }),
        [ FETCH_ASYNC ]: (state) => ({
            ...state,
            loading: true
        })
    }),
    initialState: {
        data: null,
        errors: null,
        loading: false
    },
};

const saveApiDuckTemplate = {
    actionTypesNames: [ 'SAVE_ASYNC' ],
    selectors: {
        selectErrors: (state) => state.errors,
        selectLoading: (state) => state.loading,
        selectData: (state) => state.data
    },
    reducersFactory: ({ SAVE_ASYNC }) => ({
        [SAVE_ASYNC]: (state, action) => ({
            ...state,
            data: action.payload,
            loading: true
        })
    })
};

const _makeApi = (mountPoint: string, ...overrides: Array<$Shape<DuckConfigType<*>>>) => {
    return composeDuck(baseApiDuckTemplate, { mountPoint: `API:${mountPoint.toUpperCase()}` }, ...overrides);
};

export const makeReadOnlyApi = (mountPoint: string, overrides: $Shape<DuckConfigType<*>> = {}) => {
    return _makeApi(mountPoint, overrides);
};

export const makeApiWithSave = (mountPoint: string, overrides: $Shape<DuckConfigType<*>> = {}) => {
    return _makeApi(mountPoint, saveApiDuckTemplate, overrides);
};

