//@flow
import { makeReadOnlyApi } from 'app/api/apiFactory';
import { sortOptions } from 'app/helpers/object';

import type { Dispatch } from 'redux';

const fetchAsync = (api) => (dispatch: Dispatch<*>, getState: () => *, { client }) => {
    return client({
        dispatch,
        url: '/api/reference/promotional_method',
        responseHandler(response) {
            const promotional_method = response.data.promotional_methods
                .map(({ promotional_method: { promotional_method_id: id, name } }) => ({ name, value: String(id) }));

            promotional_method.sort(sortOptions);

            dispatch(api.actionCreators.successAction(promotional_method));
        }
    });
};

export default makeReadOnlyApi('promotionalMethod', {
    actionCreatorsFactory: ({ FETCH_ASYNC }) => ({
        fetchAction() {
            return ({
                type: FETCH_ASYNC,
                payload: { actionCreator: fetchAsync },
                meta: { remote: { ttl: Infinity, concurrent: false }, api: this },
            });
        }
    })
});

