// @flow
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import * as api from 'app/api';
import createField from 'app/hoc/createField';
import componentMount from 'app/hoc/componentMount';
import l, { translateSelectOptions } from 'app/Translate';
import { notEmptyValidate } from 'app/validator/sync';
import { promotionalMethod } from './name';
import { Select } from './base';
import { optionsToChildrenSelector } from './utils';

const promotionalMethodsSelector = optionsToChildrenSelector(
    createSelector(
        api.promotionalMethod.selectors.selectData,
        api.locale.selectors.selectCurrentLanguage,
        translateSelectOptions
    )
);

const PromotionalMethodSelectField = createField(promotionalMethod, () => ({
    id: 'promotional-method',
    label: l('Partner type'),
    placeholder: l('Choose...'),
    warn: notEmptyValidate,
    maxHeight: 250,
    menuClassName: 'promotional-method-menu'
}))(Select);

export default connect(
    (state) => ({
        children: promotionalMethodsSelector(state)
    }),
    (dispatch) => ({
        onMountLoadAction() {
            dispatch(api.promotionalMethod.actionCreators.fetchAction());
        }
    })
)(componentMount(PromotionalMethodSelectField));


