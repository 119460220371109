// @flow
import * as React from 'react';
import { withHistoryControl } from 'app/components/WithHistory';
import HiddenField from 'app/default/field/HiddenField';
import { parseUrl } from 'app/helpers/url';
import type { HistoryType } from 'app/state/history';

const LocaleField = ({history}: { history: HistoryType }) =>
    <HiddenField name="locale" value={parseUrl(history.location.pathname).locale} />;

export default withHistoryControl(LocaleField);
