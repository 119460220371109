import 'babel-polyfill';
import 'assets/css/style.css';
import { parseUrl } from 'app/helpers/url';
import { setCaptchaKey, setCaptchaProvider } from 'app/state/reducers/application';
import * as React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Provider} from 'react-redux';
import LocaleProvider from 'app/components/LocaleProvider';
import withForm from 'app/hoc/withForm';
import {l} from 'app/Translate';
import store from 'app/state/configureStore';
import history from 'app/state/history';
import WithHistory from 'app/components/WithHistory';
import {setFavIcon, setTitle} from './helpers/dom';
import {css} from 'load-js-css';

// Hotfix. TODO: Move this to a better place
css({ href: '//fonts.googleapis.com/css?family=Quicksand:300,400,500,600,700' });
css({ href: '//fonts.googleapis.com/css?family=Roboto:300,400,500,600,700' });

type CustomisationComponentType = React.Component<*> & { PageTitle?: string, FavIcon?: string };
type NetworkInfoType = { name: string, id: string };

const App = (
    Customisation: CustomisationComponentType, 
    network: NetworkInfoType, 
    captchaSiteKey?: string,
    isAvailable: boolean = true,
    captchaProvider?: string, 
    confirmEmail: boolean = false,
    apiProtocol?: string,
    apiUrl?: string,
    merchantId?: string,
) => {
    store.dispatch(setCaptchaKey(captchaSiteKey));
    store.dispatch(setCaptchaProvider(captchaProvider));

    const ConnectedOnboardingApp = connect(
        (state: *) => ({ fatalErrorOccurred: state.application.hasErrored })
    )(Customisation);

    const ConnectedOnboardingAppWithForm = withForm(ConnectedOnboardingApp);

    setTitle(String(Customisation.PageTitle || network.name + ' ' + l('Signup Page')));
    Customisation.FavIcon && setFavIcon(String(Customisation.FavIcon));

    if (merchantId) {
        sessionStorage.setItem('merchantId', merchantId);
    }
    
    const AppSkeleton = (props: {}) => (
        <Provider store={store}>
            <WithHistory
                history={history}
                render={(location) => {
                    const locale = parseUrl(location.pathname).locale;
                    return (
                        <LocaleProvider locale={locale} networkName={network.name}>
                            <div style={{ height: '100%' }}>
                                <ConnectedOnboardingAppWithForm
                                    {...props}
                                    locale={locale}
                                    networkId={network.id}
                                    networkName={network.name}
                                    isAvailable={isAvailable !== false}
                                    confirmEmail={confirmEmail !== false}
                                    apiUrl={apiUrl}
                                    apiProtocol={apiProtocol}
                                />
                            </div>
                        </LocaleProvider>
                    );
                }}
            />
        </Provider>
    );

    AppSkeleton.propTypes = {
        locale: PropTypes.string.isRequired,
        networkId: PropTypes.string
    };

    return AppSkeleton;
};

export {
    App as default, App
};
