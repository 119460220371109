// @flow
import * as React from 'react';
import { EntypoChevronLeft } from 'react-entypo-icons';
import { hasSubmitSucceeded } from 'redux-form';
import Button from '@phg/stilo-toolbox/components/button';
import Link from '@phg/stilo-toolbox/components/Links/Link';
import connectWithFormName from 'app/hoc/withFormName';
import createButton from 'app/hoc/createButton';
import l from 'app/Translate';

import type { ButtonDerivedProps } from 'app/hoc/createButton';

// ======= [Continue] =======
export const ContinueButton = createButton(({ hasErrors, loading, handleNext }: ButtonDerivedProps) => (
    <Button
        id='continueBtn'
        bsStyle="success"
        text={l('Continue')}
        type="submit"
        className={(hasErrors || loading) && 'disabled'}
        disabled={hasErrors || loading}
        onClick={handleNext}
    />
));

// ======= [Submit] =======
type SubmitButtonProps = ButtonDerivedProps & {
    classes: {}
};

const containerStyle = {
    display: 'inline',
    float: 'right',
    width: 1
};

const errorStyle = {
    display: 'block',
    color: '#FD7280',
    width: 227,
    paddingTop: 10,
    fontFamily: 'Roboto',
    fontSize: 12
};

// eslint-disable-next-line no-unused-vars
const SubmitButton_ = ({ submitFailed, hasErrors, loading, classes = {}, handleSubmit }: SubmitButtonProps) => (
    <div style={containerStyle}>
        <Button
            id='continueBtn'
            bsStyle="success"
            text={l('Sign Up')}
            type="submit"
            className={(hasErrors || loading) && 'disabled'}
            onClick={handleSubmit}
        />
        {submitFailed && (
            <div style={{ position: 'relative', float: 'right' }}>
                <div style={errorStyle}>
                    {l('Signup was unsuccessful. Try again')}
                </div>
            </div>
        )}
    </div>
);

export const SubmitButton = createButton(SubmitButton_);

// ======= [Previous] =======
export const BackButton = createButton(({ handleBack }: ButtonDerivedProps) => (
    <Link
        active={false}
        onClick={handleBack}
    >
        <span>
            <EntypoChevronLeft className="larrow" />
            <span>{l('Previous step')}</span>
        </span>
    </Link>
));

// ======= [Advance progress on successful submit] =======
type NextOnSuccessProps = {
    handleNext: () => void,
    success: boolean
};

class NextOnSuccess_ extends React.Component<NextOnSuccessProps> {
    componentDidUpdate() {
        if (this.props.success) {
            this.props.handleNext();
        }
    }

    render() {
        return null;
    }
}

const ConnectedNextOnSuccess_ = connectWithFormName(
    (state, { formName }) => ({
        success: hasSubmitSucceeded(formName)(state)
    })
)(NextOnSuccess_);

export const NextOnSuccess = createButton(ConnectedNextOnSuccess_);

// ======= [Buttons set] =======
type ButtonsProps = {
    back?: boolean,
    submit?: boolean
};

export const Buttons = ({ back, submit }: ButtonsProps) => (
    <div className="buttons">
        {back && <BackButton />}
        {submit ? <SubmitButton/> : <ContinueButton/>}
    </div>
);
export default Buttons;
