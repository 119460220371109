// @flow
import createField from 'app/hoc/createField';
import l  from 'app/Translate';
import { Input } from './base';
import { addressSecond } from './name';

const AddressSecondField = createField(addressSecond, () => ({
    id: 'AddressSecond',
    type: 'text',
    label: l('Business address line 2')
}))(Input);

export default AddressSecondField;
