const networkStyleMap = {
    'maincolor':'purple',
    '$screen-xs-min': '0px',
    '$screen-xs-max': '229px',
    '$screen-s-min': '330px',
    '$screen-s-max': '767px',
    '$screen-m-min': '768px',
    '$screen-m-max': '991px',
    '$screen-l-min': '992px',
    '$screen-l-max': '1199px',
    '$screen-xl-min': '1200px',
    '$screen-xl-max': '2400px',
    '$black': '#0a0a0a',
    '$gray-darker': '#2a2a2a',
    '$gray-dark': '#3c3c3c',
    '$gray': '#6a6a6a',
    '$gray-light': '#8a8a8a',
    '$gray-lighter': '#9a9a9a',
    '$white': '#fff',
    '$brand-color01': '#cdcdcd',
    '$brand-color02': '#dddddd',
    '$brand-color03': '#ececec',
    '$brand-color04': '#e9e9e9',
    '$brand-color05': '#f0f0f0',
    '$brand-color06': '#f3f3f3',
    '$brand-color07': '#f6f6f6',
    '$brand-color08': '#f9f9f9',
    '$brand-color09': '#fcfcfc',
    '$blue': '#3BBECA',
    '$blue-dark': '#0064cd',
    '$green': '#76C100',
    '$red': '#FF552A',
    '$yellow': '#ffc40d',
    '$orange': '#FF8C24',
    '$pink': '#E54ABF',
    '$purple': '#7a43b6',
    '$color-important': '#FF552A',
    '$color-warning': '#FF8C24',
    '$color-success': '#76C100',
    '$color-info': '#3BBECA',
    '$color-default': '#9a9a9a',
    '$alert-background': '#f9f9f9',
    '$alert-background-highlight': '#f3f3f3',
    '$alert-border': '#cdcdcd',
    '$alert-text': '#6a6a6a',
    '$alert-text-shadow': 'inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
    '$alert-shadow': 'inset 0px 1px 1px rgba(255, 255, 255, 0.25), 0px 2px 2px -2px rgba(0, 0, 0, 0.2)',
    '$alert-link-color': '#6a6a6a',
    '$warning-background': '#eae596',
    '$warning-background-highlight': '#e0d67e',
    '$warning-text': '#897c00',
    '$warning-text-shadow': 'inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
    '$warning-link-color': '#897c00',
    '$warning-border': '#d8d166',
    '$error-background': '#ffc6ad',
    '$error-background-highlight': '#ffa48d',
    '$error-text': '#b55338',
    '$error-text-shadow': 'inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
    '$error-link-color': '#b55338',
    '$error-border': '#ffa28a',
    '$success-background': '#d0e6a9',
    '$success-background-highlight': '#bedd86',
    '$success-text': '#5f8120',
    '$success-text-shadow': 'inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
    '$success-link-color': '#5f8120',
    '$success-border': '#b8d682',
    '$info-background': '#e4f1fa',
    '$info-background-highlight': '#cee5f7',
    '$info-text': '#4579b8',
    '$info-text-shadow': 'inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
    '$info-link-color': '#4579b8',
    '$info-border': '#bcd9f3',
    '$body-background': '#f3f3f3',
    '$body-background-image': 'url(\'/assets/EV2/img/pat01.png\')',
    '$text-color': '#6a6a6a',
    '$link-color': '#09c',
    '$link-color-hover': 'darken($link-color, 10%)',
    '$sans-font-family': 'Arial, Helvetica, sans-serif',
    '$serif-font-family': 'Georgia, \'Times New Roman\', Times, serif',
    '$mono-font-family': 'Monaco, Menlo, Consolas, \'Courier New\', monospace',
    '$custom-font-family': '"Open Sans", Helvetica, Arial, sans-serif',
    '$font-weight-normal': '400',
    '$font-weight-bold': '600',
    '$font-weight-bolder': '700',
    '$line-height-small': '18px',
    '$line-height-smaller': '14px',
    '$base-font-size': '12px',
    '$base-font-family': 'Arial, Helvetica, sans-serif',
    '$base-line-height': '20px',
    '$alt-font-family': 'Georgia, \'Times New Roman\', Times, serif',
    '$headings-font-family': '"Open Sans", Helvetica, Arial, sans-serif',
    '$headings-font-weight': '600',
    '$headings-color': 'inherit',
    '$heading1-font-size': '36px',
    '$heading2-font-size': '30px',
    '$heading3-font-size': '24px',
    '$heading4-font-size': '18px',
    '$heading5-font-size': '14px',
    '$heading6-font-size': '12px',
    '$heading-small-text': '#8a8a8a',
    '$font-size-x-large': '16px',
    '$font-size-x-x-large': '24px',
    '$font-size-x-x-x-large': '36px',
    '$font-size-larger': '14px',
    '$font-size-large': '13px',
    '$font-size-small': '11px',
    '$font-size-mini': '10px',
    '$padding-large': '11px 19px',
    '$padding-small': '2px 10px',
    '$padding-mini': '1px 6px',
    '$base-border-radius': '4px',
    '$border-radius-large': '6px',
    '$border-radius-small': '3px',
    '$border-radius-smaller': '2px',
    '$table-background': '#f6f6f6',
    '$table-background-accent': '#f9f9f9',
    '$table-background-hover': '#f3f3f3',
    '$table-border': '#dddddd',
    '$table-success-background': 'lighten($success-background, 10%)',
    '$table-error-background': 'lighten($error-background, 8%)',
    '$table-warning-background': 'lighten($warning-background, 12%)',
    '$table-info-background': 'lighten($info-background, 2%)',
    '$table-head-background': '#f3f3f3',
    '$table-head-background-highlight': '#f9f9f9',
    '$table-head-text': '#9a9a9a',
    '$table-head-text-shadow': '#fff',
    '$table-total-background': '#f0f0f0',
    '$table-total-border': '#cdcdcd',
    '$table-total-text': '#6a6a6a',
    '$table-group-background': '#f3f3f3',
    '$table-group-background-highlight': '#f9f9f9',
    '$table-group-border': '#cdcdcd',
    '$table-group-text': '#8a8a8a',
    '$table-group-text-shadow': '#fff',
    '$table-group-badge': '#cdcdcd',
    '$table-group-badge-hover': '#FF8C24',
    '$table-group-icon': '#8a8a8a',
    '$table-status': '#cdcdcd',
    '$table-real-text': '#9a9a9a',
    '$real-time-background': '#f9f9f9',
    '$gauge-text': '#6a6a6a',
    '$gauge-title': '#9a9a9a',
    '$gauge-background-color': '#ff8c24',
    '$gauge-border-color': '#f0f0f0',
    '$editable-content-span': '#09c',
    '$editable-content-span-focus': '#3c3c3c',
    '$editable-content-background': '#fcfcfc',
    '$slick-sort-desc': 'url(/assets/EV2/img/slickgrid/sort-desc.png)',
    '$slick-sort-asc': 'url(/assets/EV2/img/slickgrid/sort-asc.png)',
    '$slick-group-collapse': 'url(/assets/EV2/img/slickgrid/collapse.gif) no-repeat center center',
    '$slick-group-expand': 'url(/assets/EV2/img/slickgrid/expand.gif) no-repeat center center',
    '$sortable-placeholder-background': '#8a8a8a',
    '$slick-cell-active-background': '#fcfcfc',
    '$slick-cell-selected-background': 'lighten($info-background, 2%)',
    '$slick-cell-editable': '#4579b8',
    '$btn-background': '#fff',
    '$btn-background-highlight': '#f0f0f0',
    '$btn-border': '#cdcdcd',
    '$btn-border-radius': '3px',
    '$btn-text': '#6a6a6a',
    '$btn-text-shadow': '0 1px 0 rgba(255, 255, 255, 1)',
    '$btn-color-text-shadow': '0 1px 0 rgba(0, 0, 0, 0.25)',
    '$btn-shadow': 'inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05)',
    '$btn-primary-background': 'lighten($link-color, 4%)',
    '$btn-primary-background-highlight': 'darken($link-color, 3%)',
    '$btn-info-background': 'lighten($blue, 5%)',
    '$btn-info-background-highlight': 'darken($blue, 7%)',
    '$btn-success-background': 'lighten($green, 4%)',
    '$btn-success-background-highlight': 'darken($green, 2%)',
    '$btn-warning-background': 'lighten($orange, 5%)',
    '$btn-warning-background-highlight': 'darken($orange, 7%)',
    '$btn-danger-background': 'lighten($red, 5%)',
    '$btn-danger-background-highlight': 'darken($red, 4%)',
    '$btn-inverse-background': 'darken($gray, 10%)',
    '$btn-inverse-background-highlight': '#3c3c3c',
    '$legend-border': '#dddddd',
    '$legend-text': '#6a6a6a',
    '$input-background': '#fff',
    '$input-border': '#cdcdcd',
    '$input-focus-border': 'rgba(0, 153, 204, .6)',
    '$input-border-radius': '2px',
    '$input-disabled-background': '#f3f3f3',
    '$form-actions-background': '#f9f9f9',
    '$form-actions-border': '#cdcdcd',
    '$input-height': '$base-line-height + 10px',
    '$input-text': '#6a6a6a',
    '$append-background': '#f0f0f0',
    '$append-border': '#cdcdcd',
    '$append-text-shadow': '0 1px 0 rgba(255, 255, 255, 1)',
    '$pre-background': '#f9f9f9',
    '$pre-border': '#cdcdcd',
    '$pre-text': '#6a6a6a',
    '$prettyprint-background': '#f6f6f6',
    '$prettyprint-border': '#dddddd',
    '$prettyprint-text': '#8a8a8a',
    '$linenums-text': '#9a9a9a',
    '$linenums-text-shadow': '0 1px 0 $white',
    '$linenums-shadow1': '#f9f9f9',
    '$linenums-shadow2': '#dddddd',
    '$linenums-pln': '#6a6a6a',
    '$linenums-tag': '#09c',
    '$linenums-com': '#9a9a9a',
    '$dropdown-background': '#fff',
    '$dropdown-border': 'rgba(0,0,0,.2)',
    '$dropdown-border-radius': '4px',
    '$dropdown-divider-top': '#ececec',
    '$dropdown-divider-bottom': '#fff',
    '$dropdown-link-color': '#3c3c3c',
    '$dropdown-link-color-hover': '#fff',
    '$dropdown-link-color-active': '#fff',
    '$dropdown-link-background-active': '#09c',
    '$dropdown-link-background-hover': '#09c',
    '$zindex-dropdown': '1040',
    '$zindex-popover': '1040',
    '$zindex-tooltip': '1040',
    '$zindex-fixed-navbar': '1030',
    '$zindex-modal-backdrop': '1040',
    '$zindex-modal': '1050',
    '$icon-sprite-path': '\'/assets/EV2/img/glyphicons-halflings.png\'',
    '$icon-white-sprite-path': '\'/assets/EV2/img/glyphicons-halflings-white.png\'',
    '$placeholder-text': '#8a8a8a',
    '$hr-border': '#cdcdcd',
    '$hr-border-bottom': '#fff',
    '$horizontal-component-offset': '180px',
    '$navbar-collapse-width': '979px',
    '$navbar-collapse-desktop-width': '$navbar-collapse-width + 1',
    '$navbar-height': '54px',
    '$navbar-font-family': '"Open Sans", Helvetica, Arial, sans-serif',
    '$navbar-font-size': '14px',
    '$navbar-font-weight': '400',
    '$navbar-font-transform': 'none',
    '$navbar-link-background-shadow': 'none',
    '$navbar-background': '#3c3c3c',
    '$navbar-background-highlight': '#3c3c3c',
    '$navbar-border': '#f3f3f3',
    '$navbar-text': '#6a6a6a',
    '$navbar-link-color': '#8a8a8a',
    '$navbar-link-color-hover': '#fff',
    '$navbar-link-color-active': '#fff',
    '$navbar-link-background-hover': 'transparent',
    '$navbar-link-background-active': 'transparent',
    '$nav-collapse-link-background': 'darken($gray-dark, 4%)',
    '$nav-collapse-link-color': '#fff',
    '$navbar-text-shadow': 'none',
    '$navbar-search-background': '#3c3c3c',
    '$navbar-search-background-focus': '#f3f3f3',
    '$navbar-search-border': '#3c3c3c',
    '$navbar-search-shadow': 'inset 0 1px 2px rgba(0,0,0,.1), 0 1px 0 rgba(255,255,255,.15)',
    '$navbar-search-placeholder-color': '#cdcdcd',
    '$navbar-search-text': '#dddddd',
    '$navbar-search-text-focus': '#2a2a2a',
    '$brand-background': 'url(\'/assets/EV2/img/logo.svg\') no-repeat 20px 13px transparent',
    '$brand-width': '85px',
    '$brand-padding': '(($navbar-height - $base-line-height) / 2) 10px (($navbar-height - $base-line-he' +
    'ight) / 2) 20px',
    '$brand-text-indent': '-9999em',
    '$brand-height': 'auto',
    '$navbar-brand-color': '#fff',
    '$navbar-badge-background': '#3c3c3c',
    '$navbar-badge-border': '#3c3c3c',
    '$navbar-badge-shadow': 'none',
    '$navbar-badge-text': '#6a6a6a',
    '$navbar-badge-link-color': '#8a8a8a',
    '$navbar-badge-link-color-hover': '#fff',
    '$navbar-divider-left': '#3c3c3c',
    '$navbar-divider-right': '#3c3c3c',
    '$subnav-background': '#f9f9f9',
    '$subnav-background-highlight': '#f9f9f9',
    '$subnav-top-border': '#cdcdcd',
    '$subnav-bottom-border': '#cdcdcd',
    '$subnav-shadow': '0px 2px 2px -2px rgba(0, 0, 0, 0.2)',
    '$subnav-text': '#6a6a6a',
    '$subnav-text-shadow': '#fff',
    '$subnav-link-color': '#6a6a6a',
    '$subnav-link-color-hover': '#3c3c3c',
    '$subnav-link-color-active': '#09c',
    '$pagination-background': '#f3f3f3',
    '$pagination-background-highlight': '#f9f9f9',
    '$pagination-shadow': '#fff',
    '$pagination-border': '#dddddd',
    '$pagination-active-background': '#f6f6f6',
    '$hero-unit-background': '#9a9a9a',
    '$hero-unit-heading-color': 'inherit',
    '$hero-unit-lead-color': 'inherit',
    '$tooltip-color': '#fff',
    '$tooltip-background': '#0a0a0a',
    '$tooltip-arrow-width': '5px',
    '$tooltip-arrow-color': '#0a0a0a',
    '$tooltip-opacity': '85',
    '$popover-background': '#fcfcfc',
    '$popover-arrow-width': '8px',
    '$popover-arrow-color': '#fcfcfc',
    '$popover-title-background': 'darken($popover-background, 3%)',
    '$popover-title-background-highlight': '#fcfcfc',
    '$popover-arrow-outer-width': '$popover-arrow-width + 1',
    '$popover-arrow-outer-color': 'rgba(0,0,0,.25)',
    '$modal-backdrop': '#0a0a0a',
    '$modal-background': '#f9f9f9',
    '$modal-border': '#cdcdcd',
    '$modal-text': '#6a6a6a',
    '$modal-text-shadow': '#fff',
    '$modal-head-background': '#f0f0f0',
    '$modal-head-background-highlight': '#f9f9f9',
    '$modal-head-text': '#6a6a6a',
    '$modal-head-font-size': '13px',
    '$modal-footer-background': '#f6f6f6',
    '$modal-footer-shadow': '0 1px 0 $white inset',
    '$label-badge-background': 'lighten($gray-lighter, 10%)',
    '$label-badge-text-shadow': '0 1px 0 rgba(0,0,0,.15)',
    '$note-badge-background': 'lighten($red, 5%)',
    '$note-badge-background-highlight': 'darken($red, 4%)',
    '$note-badge-border': '1px solid $red',
    '$note-badge-shadow': 'inset 0 0 rgba(255, 255, 255, 0.3), 0 1px 1px rgba(0, 0, 0, 0.08)',
    '$note-badge-font-family': '"Open Sans", Helvetica, Arial, sans-serif',
    '$note-badge-font-size': '12px',
    '$note-badge-text': '#fff',
    '$note-badge-text-shadow': '0px 1px 0px rgba(0, 0, 0, .25)',
    '$bread-crumb-background': '#f6f6f6',
    '$bread-crumb-border': '#dddddd',
    '$bread-crumb-divider': '#cdcdcd',
    '$bread-crumb-text-shadow': '0 1px 0 $white',
    '$bread-crumb-text-active': '#6a6a6a',
    '$progress-background': '#ececec',
    '$progress-background-highlight': '#e9e9e9',
    '$n-progress-background': '#3BBECA',
    '$n-progress-background-highlight': 'lighten($blue, 10%)',
    '$n-progress-spinner-border': '#3BBECA',
    '$nav-header-text': '#8a8a8a',
    '$nav-header-text-shadow': '0 1px 0 rgba(255, 255, 255, 0.5)',
    '$muted-text': '#9a9a9a',
    '$blockquote-border': '#dddddd',
    '$blockquote-small-color': '#9a9a9a',
    '$terms-background': '#fcfcfc',
    '$terms-border': '#dddddd',
    '$terms-scroller-background': '#f0f0f0',
    '$terms-scroller-border': '#dddddd',
    '$stat-counter-background': '#f3f3f3',
    '$stat-counter-background-highlight': '#f9f9f9',
    '$stat-counter-border': '#cdcdcd',
    '$stat-counter-border-radius': '4px',
    '$stat-counter-text': '#6a6a6a',
    '$stat-counter-text-shadow': '#fff',
    '$stat-counter-color': '#cdcdcd',
    '$stat-counter-color1': '#FF8C24',
    '$stat-counter-color2': '#76C100',
    '$stat-counter-color3': '#3BBECA',
    '$stat-counter-color4': '#E54ABF',
    '$stat-counter-color5': '#FF552A',
    '$widget-background': '#f9f9f9',
    '$widget-border': '#cdcdcd',
    '$widget-border-radius': '4px',
    '$widget-shadow': '0px 2px 2px -2px rgba(0, 0, 0, 0.2)',
    '$widget-text': '#6a6a6a',
    '$widget-text-shadow': '#fff',
    '$widget-icon-border': '#cdcdcd',
    '$widget-head-background': '#ececec',
    '$widget-head-background-highlight': '#f9f9f9',
    '$widget-head-border': '#cdcdcd',
    '$widget-head-title': '#6a6a6a',
    '$widget-head-font-size': '13px',
    '$widget-head-icon': '#8a8a8a',
    '$widget-head-icon-hover': '#6a6a6a',
    '$widget-head-icon-size': '14px',
    '$widget-tab-active-text': '#6a6a6a',
    '$widget-options-background': '#f6f6f6',
    '$widget-options-border': '#cdcdcd',
    '$widget-options-shadow': '#fff',
    '$widget-options-text': '#6a6a6a',
    '$widget-options-icon': '#8a8a8a',
    '$widget-options-icon-hover': '#6a6a6a',
    '$widget-content-background': '#f9f9f9',
    '$widget-footer-background': '#f3f3f3',
    '$widget-footer-background-highlight': '#f9f9f9',
    '$widget-footer-border': '#cdcdcd',
    '$widget-footer-text': '#6a6a6a',
    '$widget-footer-text-shadow': '#fff',
    '$well-background': '#f9f9f9',
    '$well-border': '1px solid $brand-color01',
    '$well-shadow': '0px 2px 2px -2px rgba(0, 0, 0, 0.2)',
    '$graph-text': '#8a8a8a',
    '$graph-line-stroke': '2px',
    '$graph-line-dot-fill': '#f9f9f9',
    '$graph-line-dot-stroke': '2px',
    '$previous-line': '#dddddd',
    '$previous-line-area': '#dddddd',
    '$previous-line-dot-stroke': '#dddddd',
    '$graph-line-area': '0.3',
    '$overlay-line-area-opacity': '0.1',
    '$overlay-line-opacity': '0.6',
    '$graph-background-grid': '#dddddd',
    '$graph-x-label': '#9a9a9a',
    '$graph-y-label': '#9a9a9a',
    '$graph-tooltip-background': '#0a0a0a',
    '$graph-tooltip-border': '#0a0a0a',
    '$graph-tooltip-text': '#fff',
    '$graph-tooltip-label': '#fff',
    '$graph-pivot': '#0a0a0a',
    '$table-sort-icon': 'url(\'/assets/EV2/img/sort_both.png\') no-repeat center right transparent',
    '$table-sort-up-icon': 'url(\'/assets/EV2/img/sort_asc.png\') no-repeat center right $brand-color05',
    '$table-sort-down-icon': 'url(\'/assets/EV2/img/sort_desc.png\') no-repeat center right $brand-color05',
    '$table-sort-odd-color': '#fcfcfc',
    '$table-sort-even-color': '#f9f9f9',
    '$checkable-background': 'url(\'/assets/EV2/img/checkables.png\')',
    '$switch-border': '#cdcdcd',
    '$switch-background': '#f9f9f9',
    '$switch-background-highlight': '#f0f0f0',
    '$switch-off-background': '#f0f0f0',
    '$switch-off-background-highlight': '#e9e9e9',
    '$switch-text': '#6a6a6a',
    '$switch-off-text': '#8a8a8a',
    '$date-range-label': '#2a2a2a',
    '$date-range-label-shadow': '#fff',
    '$date-range-list-background': '#f9f9f9',
    '$date-range-list-border': '#f6f6f6',
    '$date-range-calendar-background': '#fff',
    '$date-range-calendar-border': '#f6f6f6',
    '$date-range-background': '#fff',
    '$date-range-arrow': '#fff',
    '$date-range-table-background': '#f9f9f9',
    '$date-range-off-text': '#9a9a9a',
    '$date-in-range-background': '#e4f1fa',
    '$date-range-hover-background': '#fff',
    '$date-range-active-background': 'lighten($link-color, 4%)',
    '$date-range-active-background-hightlight': 'darken($link-color, 3%)',
    '$date-range-active-border': '#cdcdcd',
    '$date-range-active-text': '#fff',
    '$date-range-active-text-shadow': '0 1px 0 rgba(0, 0, 0, 0.25)',
    '$date-range-week-text': '#cdcdcd',
    '$slider-height': '14px',
    '$slider-track-background': '#ececec',
    '$slider-track-background-highlight': '#e9e9e9',
    '$slider-selection-background': '#e4f1fa',
    '$slider-selection-background-highlight': '#cee5f7',
    '$slider-handle-background': '#3BBECA',
    '$slider-handle-background-highlight': '#0064cd',
    '$slider-handle-border': '#3BBECA',
    '$slider-handle-opacity': '.8',
    '$slider-border-radius': '4px',
    '$slider-disabled-track-background': 'lighten($progress-background, 5%)',
    '$slider-disabled-track-background-highlight': 'lighten($progress-background-highlight, 5%)',
    '$slider-disabled-selection-background': '#fff',
    '$slider-disabled-selection-background-highlight': '#f0f0f0',
    '$slider-disabled-handle-background': '#ececec',
    '$slider-disabled-handle-background-highlight': '#fff',
    '$sidebar-widget-input-background': '#464646',
    '$sidebar-widget-input-background-focus': '#494949',
    '$sidebar-widget-input-border': '#5a5a5a',
    '$sidebar-widget-input-border-focus': '#6a6a6a',
    '$sidebar-widget-input-text': '#ffffff',
    '$btn-insight-background': 'lighten($blue, 5%)',
    '$btn-insight-background-highlight': 'darken($blue, 7%)',
    '$sidebar-background': '#434343',
    '$sidebar-border': '#5a5a5a',
    '$sidebar-top': '91px',
    '$sidebar-widget-background': '#4a4a4a',
    '$sidebar-widget-border': '#5a5a5a',
    '$sidebar-widget-text': '#eeeeee',
    '$sidebar-widget-text-shadow': '#1a1a1a',
    '$sidebar-widget-icon-border': '#5a5a5a',
    '$sidebar-widget-head-background': '#404040',
    '$sidebar-widget-head-background-highlight': '#4a4a4a',
    '$sidebar-widget-head-border': '#5a5a5a',
    '$sidebar-widget-head-title': '#eeeeee',
    '$sidebar-widget-head-icon': '#dddddd',
    '$sidebar-widget-head-icon-hover': '#ffffff',
    '$sidebar-widget-options-background': '#404040',
    '$sidebar-widget-options-border': '#5a5a5a',
    '$sidebar-widget-options-shadow': '#1a1a1a',
    '$sidebar-widget-options-text': '#dddddd',
    '$sidebar-widget-options-icon': '#dddddd',
    '$sidebar-widget-options-icon-hover': '#ffffff',
    '$sidebar-widget-footer-background': '#464646',
    '$sidebar-widget-footer-background-highlight': '#4a4a4a',
    '$sidebar-widget-footer-border': '#5a5a5a',
    '$sidebar-widget-footer-text': '#dddddd',
    '$sidebar-widget-footer-text-shadow': '#1a1a1a',
    '$sidebar-nav-pills-background': '#404040',
    '$sidebar-nav-pills-link-color': '#ffffff',
    '$sidebar-nav-pills-caret': '#ffffff',
    '$sidebar-table-background': '#434343',
    '$sidebar-table-background-accent': '#464646',
    '$sidebar-table-background-hover': '#494949',
    '$sidebar-table-border': '#5a5a5a',
    '$sidebar-table-text': '#fff',
    '$sidebar-table-link': '#3BBECA',
    '$sidebar-table-head-background': '#404040',
    '$sidebar-table-head-background-highlight': '#464646',
    '$sidebar-table-head-text': '#3BBECA',
    '$sidebar-table-head-text-shadow': '0 1px 0px #1a1a1a',
    '$sidebar-table-sort-icon': 'url(\'../img/sort_both.png\') no-repeat center right transparent',
    '$sidebar-table-sort-up-icon': 'url(\'../img/sort_asc.png\') no-repeat center right #404040',
    '$sidebar-table-sort-down-icon': 'url(\'../img/sort_desc.png\') no-repeat center right #404040',
    '$sidebar-table-sort-odd-color': '#434343',
    '$sidebar-table-sort-even-color': '#404040',
    '$sidebar-checkable-background': 'url(\'../img/checkables-sidebar.png\')',
    '$font-awesome-path': '\'/assets/EV2/font\'',
    '$brand-success' : 'blue'
};

export default networkStyleMap;