// @flow
import * as React from 'react';
import { l } from 'app/Translate';

const Gratulations = () => (
    <div className="congrats-memo">
        <div>{l('Welcome to the program!')}</div>
        <div>
            {l('You will receive an email soon that includes your account token as well as instructions on how to create links ' +
                'and set up banking credentials on the Partnerize platform so you can get paid.')}
        </div>
    </div>
);

export default Gratulations;
