const normalizeLocale = (lang: string) => {
    return lang.replace('-', '_').toLowerCase();
};

const findTerms = (haystack, type, locale) => {
    const terms = haystack.filter(({ tnc_type }) => tnc_type === type);
    locale = normalizeLocale(locale);

    let term = terms.find((t) => t.locale === locale || normalizeLocale(t.locale) === locale);
    if (!term) {
        const lang = locale.slice(0, 2);
        term = terms.find((t) => t.locale === lang);
    }

    if (!term) {
        term = terms[0];
    }

    return term || {};
};


const findCampaignTerms = (terms, locale) => {
    locale = normalizeLocale(locale);

    let term = terms.find((t) => t.locale === locale || normalizeLocale(t.locale) === locale);
    if (!term) {
        const lang = locale.slice(0, 2);
        term = terms.find((t) => t.locale === lang);
    }

    if (!term) {
        term = terms[0];
    }

    return term || {};
};


export { findTerms, findCampaignTerms };
