// @flow
import * as React from 'react';
import Input from '@phg/stilo-toolbox/components/Forms/Input';
import {Tooltip, OverlayTrigger} from '@phg/stilo-toolbox/components/Tooltip';
import type { InputProps } from 'redux-form/lib/FieldProps.types';

export type TextFieldPropsType = {
    id: string,
    type: string,
    label: React.Node,
    tooltip: string,
    input: InputProps,
    placeholder: string
};

const renderField = ({input, label, type = 'text', id = '', tooltip, placeholder }: TextFieldPropsType) => {
    const inputField  = (
        <Input
            {...input}
            onBlur={(synthEvent) => input.onBlur(synthEvent.target.value)}
            handleChange={input.onChange}
            label={label}
            placeholder={placeholder||label}
            type={type}
            colwidths={[6, 6]}
            id={id}
            className={'label'+id}
        />
    );

    if (tooltip) {
        return (
            <OverlayTrigger placement="right" id={'tooltipoverlay'+id} overlay={<Tooltip id={'tooltip'+id}>{tooltip}</Tooltip>}>
                {inputField}
            </OverlayTrigger>
        );
    } else {
        return inputField;
    }
};

export default renderField;