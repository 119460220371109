//@flow
import { makeReadOnlyApi } from 'app/api/apiFactory';
import { addError } from 'app/state/reducers/application';
import get from 'app/connect/get';
import { setLocale } from 'app/Translate';

import type { Dispatch } from 'redux';

async function fetch(language: string, network?: string) {
    let url = '/locale';
    
    if (network) {
        url = url.concat(`/${network}`);
    }

    url = url.concat(`/${language.replace(/_/g, '-')}.json`);

    const response = await get(url);

    if (response && response.status === 200) {
        return response.data;
    }

    throw new Error('Couldn\'t fetch locales');
}

// eslint-disable-next-line complexity
async function fetchWithFallback(language: string, network?: string) {
    const attempts = [
        [language, network]
    ];

    if (network) {
        attempts.push([language]);
    }

    if (language !== 'en') {
        attempts.push(['en']);
    }

    for (const attempt of attempts) {
        try {
            const data = await fetch.apply(null, attempt);
            return { ...data, language: attempt[0] };
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                // eslint-disable-next-line no-console
                console.error(`Couldn't fetch localizations for: ${language}, ${network || 'default'}`);
            }
        }
    }

    return { 
        language: 'en', 
        cldr: {},
        locales: []
    };
}

const fetchAsync = (api, { language, network }) => async (dispatch: Dispatch<*>) => {
    try {
        const i18n = await fetchWithFallback(language, network);
        dispatch(api.actionCreators.successAction(i18n));
    } catch (e) {
        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.error(e);
        }
        dispatch(addError(new Date, 'Couldn\'t fetch translation!'));
    }
};

export default makeReadOnlyApi('locale', {
    actionCreatorsFactory: ({ FETCH_ASYNC }) => ({
        fetchAction(language, network) {
            return ({
                type: FETCH_ASYNC,
                payload: { actionCreator: fetchAsync, props: { language, network } },
                meta: {
                    remote: { 
                        ttl: Infinity,
                        concurrent: false,
                        parametric: ({ language, network }) => language + '.' + network
                    },
                    api: this,
                },
            });
        },
    }),
    selectors: {
        selectCurrentLanguage(state) {
            return state.data ? state.data.language : 'en';
        }
    },
    reducersFactory: ({ SUCCESS }) => ({
        [ SUCCESS ]: (state, action) => {
            setLocale(action.payload.locales);
            return ({
                ...state,
                data: action.payload,
                errors: null,
                loading: false
            });
        }
    })
});
