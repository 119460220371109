// @flow
import createField from 'app/hoc/createField';
import {
    notEmptyValidate
} from 'app/validator/sync';
import l from 'app/Translate';
import { Input } from './base';
import { phone } from './name';

const PhoneField = createField(phone, () => ({
    id: 'PhoneNumber',
    type: 'text',
    label: l('Phone Number'),
    warn: notEmptyValidate
}))(Input);

export default PhoneField;
