// @flow
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import makeClient from 'app/connect/client';
import * as api from 'app/api';

import sagas from './sagas';
import legacyReducers from './reducers';
import asyncMiddleware from './middleware/asyncMiddleware';

import * as ducks from './ducks';

import type { DuckType } from './duckFactory';

export const mapDucksToReducers = (...duckSets: Array<Array<DuckType>>) => {
    const result = {};
    
    for (const ducks of duckSets) {
        for (const duck of ducks) {
            result[duck.mountPoint] = duck.reducer;
        }
    }
    
    return result;
};

const makeRootReducer = () => {
    const reducers = mapDucksToReducers(Object.values(api), Object.values(ducks));
    return combineReducers({
        ...legacyReducers,
        ...reducers
    });
};

const createStoreInstance = (reducer, middleware) => {
    if (process.env.NODE_ENV === 'development') {
        //if redux tools available:
        if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
            const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
            return createStore(reducer, composeEnhancers(middleware));
        }
    }

    return createStore(reducer, middleware);
};

const makeStore = () => {
    const sagaMiddleware = createSagaMiddleware();

    const middleware = applyMiddleware(
        asyncMiddleware,
        thunk.withExtraArgument({ client: makeClient() }),
        sagaMiddleware
    );
    
    const store = createStoreInstance(makeRootReducer(), middleware);
    sagaMiddleware.run(sagas);

    return store;
};

export const store = makeStore();
export default store;

if (process.env.NODE_ENV === 'development') {
    window.store = store;
}
