// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { Loader } from '@phg/stilo-toolbox/components';

import * as api from 'app/api';

type LocaleProviderProps = {
    loading: boolean,
    loadLocales: (string, string) => void,
    
    children: React.Node,
    networkName: string,
    locale: string
};

class LocaleProvider extends React.Component<LocaleProviderProps> {
    componentDidMount() {
        this.props.loadLocales(this.props.locale, this.props.networkName);
    }
    
    componentWillReceiveProps(props: LocaleProviderProps) {
        if (props.locale !== this.props.locale || props.networkName !== this.props.networkName) {
            this.props.loadLocales(props.locale, props.networkName);
        }
    }
    
    render() {
        return this.props.loading ? <Loader /> : this.props.children;
    }
}

export default connect(
    (state) => ({
        loading: !api.locale.selectors.selectData(state)
    }),
    (dispatch) => ({
        loadLocales(locale, networkName) {
            dispatch(api.locale.actionCreators.fetchAction(locale, networkName));
        }
    })
)(LocaleProvider);
