import { devLog } from 'app/helpers/dev';
import remoteMeta from 'app/state/ducks/remoteMeta';

function decorateAsyncCall(call, onSuccess, onFailure) {
    return (...args) => {
        const result = call(...args);
        if (!(result instanceof Promise)) {
            devLog('%cAsync call should be a Promise', 'color: orange');
            return (onSuccess && onSuccess(result), result);
        }

        return result.then(
            (result) => (onSuccess && onSuccess(result), result),
            (error) => {
                onFailure && onFailure(error);
                throw error;
            }
        );
    };
}

// eslint-disable-next-line complexity
export default ({ dispatch, getState }) => (next) => (action) => {
    if (action.meta && action.meta.remote && action.meta.api) {
        const options = Object.assign({
            ttl: 0, //msec
            force: false,
            concurrent: true,
            autoRefetch: false,
            parametric: false // (props) => uniqueKey
        }, action.meta.remote);

        const metaKey = action.type;
        let propHash = '';
        
        if (typeof options.parametric === 'function') {
            propHash = options.parametric(action.payload.props);
        }
        
        const inProgress = remoteMeta.selectors.getInProgress(getState(), metaKey);
        if (inProgress && !options.concurrent) {
            return devLog(
                `%cSkipping remote call %c${action.type} %c${propHash} %ccause its another instance is already running.`,
                'color: blue', 'font-weight: bold', 'font-style: italic', 'color: blue'
            );
        }

        const now = (new Date).getTime();
        const storedHash = remoteMeta.selectors.getHash(getState(), metaKey);
        const lastCallStamp = remoteMeta.selectors.getTimestamp(getState(), metaKey);
        if (options.force || propHash !== storedHash || lastCallStamp === 0 || (lastCallStamp + options.ttl) < now) {
            const result = next(action);

            const actionCreator = decorateAsyncCall(
                action.payload.actionCreator(action.meta.api, action.payload.props),
                () => {
                    dispatch(remoteMeta.actionCreators.setRequestSuccess(metaKey));
                    if (options.autoRefetch && options.ttl > 0 && options.ttl !== Infinity) {
                        setTimeout(() => dispatch(action), options.ttl);
                    }
                },
                () => dispatch(remoteMeta.actionCreators.setRequestFailed(metaKey))
            );

            dispatch(remoteMeta.actionCreators.setRequestStart(metaKey, propHash));
            dispatch(actionCreator);

            return result;
        }

        return devLog(
            `%cSkipping remote call %c${action.type} %c${propHash} %ccause its not expired yet. ` +
            `(ttl is: ${options.ttl} ms, ${Math.abs(now - (lastCallStamp + options.ttl))} ms left)`,

            'color: blue', 'font-weight: bold', 'font-style: italic', 'color: blue'
        );
    }

    return next(action);
};
