// @flow
import * as React from 'react';
import getDisplayName from 'react-display-name';
import { filterProps } from '@phg/stilo-utils/Objects';

export const createFieldContextProxy = (Component: React.ElementType) => {
    const cleanProps = filterProps(['_onChange', '_onVerify', '_onBlur', '_onFocus', '_onDrop', '_onDragStart']);

    class FieldContextProxy extends React.Component<*, *> {
        static displayName = `FieldContextProxy(${getDisplayName(Component)})`;

        onFocus = (event: *) => {
            if (this.props.input.onFocus) {
                this.props.input.onFocus(event);
            }
            if (this.props._onFocus) {
                this.props._onFocus.call(this._props, event);
            }
        };
        
        onBlur = (valueOrEvent: *) => {
            if (this.props.input.onBlur) {
                this.props.input.onBlur(valueOrEvent);
            }
            if (this.props._onBlur) {
                this.props._onBlur.call(this._props, valueOrEvent);
            }
        };
        
        onChange = (valueOrEvent: *) => {
            if (this.props.input.onChange) {
                this.props.input.onChange(valueOrEvent);
            }
            if (this.props._onChange) { 
                this.props._onChange.call(this._props, valueOrEvent);
            }
        };
        
        onVerify = (valueOrEvent: *) => {
            if (this.props.input.onVerify) {
                this.props.input.onVerify(valueOrEvent);
            }
            if (this.props._onVerify) { 
                this.props._onVerify.call(this._props, valueOrEvent);
            }
        };
        
        onDragStart = (event: *) => {
            if (this.props.input.onDragStart) {
                this.props.input.onDragStart(event);
            }
            if (this.props._onDragStart) {
                this.props._onDragStart.call(this._props, event);
            }
        };
        
        onDrop = (event: *) => {
            if (this.props.input.onDrop) {
                this.props.input.onDrop(event);
            }
            if (this.props._onDrop) {
                this.props._onDrop.call(this._props, event);
            }
        };

        _generateProps = ({ input, ...props }: *) => {
            return {
                ...cleanProps(props),
                input: {
                    value: input.value,
                    checked: input.checked,
                    onFocus: this.onFocus,
                    onChange: this.onChange,
                    onVerify: this.onChange,
                    onBlur: this.onBlur,
                    onDragStart: this.onDragStart,
                    onDrop: this.onDrop
                }
            };
        };

        _props = this._generateProps(this.props);

        componentWillReceiveProps(props: *) {
            if (props !== this.props) {
                this._props = this._generateProps(props);
            }
        }
          
        render() {
            return React.createElement(Component, this._props);
        }
    }
    
    return FieldContextProxy;
};

export default createFieldContextProxy;