// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import createField from 'app/hoc/createField';
import { notEmptyValidate } from 'app/validator/sync';
import { Recaptcha as BaseRecaptcha } from './base';
import { recaptcha } from './name';

const Recaptcha = createField(recaptcha, () => ({
    id: 'Recaptcha',
    validate: notEmptyValidate
}))(BaseRecaptcha);

const ConditionalCaptchaField = (props) => props.sitekey ? React.createElement(Recaptcha, props) : null;

export default connect(
    (state) => ({
        sitekey: state.application.captchaSiteKey
    })
)(ConditionalCaptchaField);
