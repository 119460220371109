//@flow
import { reducer } from 'redux-form';

const ADD_FIELD_SERVER_ERROR: string = 'ADD_FIELD_SERVER_ERROR';
const RESET_FIELD_SERVER_ERROR: string = 'RESET_FIELD_SERVER_ERROR';

const addFieldNetworkError = (field: string, error: string) => {
    return {type: ADD_FIELD_SERVER_ERROR, payload: {field, error}};
};

const resetFieldServerErrors = () => {
    return {type: RESET_FIELD_SERVER_ERROR};
};

/*eslint complexity: ["error", 100]*/
const formReducer = reducer.plugin({
    signupForm: (state: *, action: {
        type: string, payload: { field?: string, error?: string, user?: string, password?: string }
    }) => {
        switch (action.type) {
            case ADD_FIELD_SERVER_ERROR:
                return {
                    ...state,
                    fields: {
                        ...state.fields,
                        [action.payload.field]: {
                            ...state.fields[action.payload.field],
                            networkError: action.payload.error,
                        }
                    }
                };

            /* eslint-disable no-case-declarations */
            case RESET_FIELD_SERVER_ERROR:
                const fields = {...state.fields};
                Object.keys(fields).map(function (key) {
                    fields[key] = {
                        ...fields[key],
                        networkError: null
                    };
                });
                return {
                    ...state,
                    fields
                };

            default:
                return state;
        }
    }
});

export {
    formReducer as default, addFieldNetworkError, resetFieldServerErrors
};
