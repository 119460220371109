export default () => ({
    Modal: {
        maxHeight: '80vh',
        maxWidth: '80vw',
        display: 'flex',
        flexDirection: 'column',

        '& > div:nth-child(1)': {
            flexShrink: 0
        },

        '& > div:nth-child(2)': {
            overflow: 'auto',
            padding: 0,
            margin: 20
        }
    }
});
