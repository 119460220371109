// @flow 
import * as React from 'react';
import getDisplayName from 'react-display-name';
import { connect } from 'react-redux';
import { FormName } from 'redux-form';

const connectWithFormName = (...args: *) => (Wrapped: React.ComponentType<*>) => {

    const Connected = connect(...args)(Wrapped);
       
    const WithFormName = (props: *) => (
        <FormName>{({ form }) => <Connected {...props} formName={form} />}</FormName>
    );

    WithFormName.displayName = `WithFormName(${getDisplayName(Wrapped)})`;

    return WithFormName;
};

export default connectWithFormName;
