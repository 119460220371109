import mixpanel from './mixpanel';

const rootSaga = function* () {
    yield [
        mixpanel()
    ];
};

export {
    rootSaga as default
};
