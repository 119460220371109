// @flow
import * as React from 'react';
import getDisplayName from 'react-display-name';
import { Field } from 'redux-form';
import getIn from 'redux-form/lib/structure/plain/getIn';
import { filterProps } from '@phg/stilo-utils/Objects';
import type { MappedNameFieldType } from './types';

const buildValidatorsList = (props) => {
    const keySet = (Object.keys(props._warn || {}))
        .reduce((acc, key) => (acc.add(key), acc), new Set);

    return Array.from(
        (Object.keys(props._validate || {}))
            .reduce((acc, key) => (acc.add(key), acc), keySet)
    );
};

export const createFieldPropsMapper = (Component: React.ElementType, names: MappedNameFieldType) => {
    const blacklist = Array.from(Object.values(names).reduce((acc, name) => {
        const prefix = name.split('.')[0];
        if (prefix) {
            acc.add(prefix);
        }

        return acc;
    }, new Set(['_onChange', '_onVerify', '_onBlur', '_onFocus', '_onDrop', '_onDragStart', '_warn', '_validate'])));

    const cleanProps = filterProps(blacklist);

    const translateProps = (props) => {
        // eslint-disable-next-line flowtype/no-weak-types
        const result: Object = cleanProps(props);
        result.names = names;
        
        return Object.entries(names).reduce((result, [short, original]) => {
            const value = getIn(props, original);
            if (value) {
                result[ short ] = value;
            }
            return result;
        }, result);
    };
    
    class FieldPropsMapper extends React.Component<*, *> {
        static displayName = `FieldPropsMapper(${getDisplayName(Component)})`;

        static defaultProps = {
            _warn: {},
            _validate: {}
        };

        _props: {} = translateProps(this.props);
        _validators: string[] = buildValidatorsList(this.props);
        
        componentWillReceiveProps(props: *) {
            if (props !== this.props) {
                this._props = translateProps(props);
                this._validators = buildValidatorsList(props);
            }
        }

        render() {
            const element = React.createElement(Component, this._props);
            if (this._validators.length > 0) {
                return (
                    <React.Fragment>
                        {element}
                        {this._validators.map((key) => (
                            <Field
                                key={key}
                                name={names[key]}
                                warn={this.props._warn[key]}
                                validate={this.props._validate[key]}
                                component={(): ?React.Element<*> => null}
                            />
                        ))}
                    </React.Fragment>
                );
            }

            return element;
        }
    }

    return FieldPropsMapper;
};

export default createFieldPropsMapper;
