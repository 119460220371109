// @flow
import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hasSubmitSucceeded } from 'redux-form';
import { EntypoCheck } from 'react-entypo-icons';

import ColumnGrid, {Column} from '@phg/stilo-toolbox/components/ColumnGrid';
import { makeTheme, withStyles, withTheme } from '@phg/stilo-toolbox/components/ThemeProvider';
import Panel from '@phg/stilo-toolbox/components/Panel';

import {l} from 'app/Translate';
import Stepper from 'app/components/Stepper';
import Error from 'app/components/errorComponent';
import LanguageSelector from 'app/components/LanguageSelector';
import TopBar from 'app/default/components/topbar';
import { HiddenField } from 'app/default/field';

import Footer from './components/Footer';
import networkStyleMapOverwrite from './styleMapOverwrite';
import networkStyleMap from './styleMap';
import makeStyle from './style';

// Steps
import WelcomeComponent from './steps/WelcomeComponent';
import CompanyComponent from './steps/CompanyComponent';
import TermsComponent from './steps/TermsComponent';
import Gratulations from './steps/Gratulations';


const Customisation = ({ classes, networkId, fatalErrorOccurred, registrationCompleted, isAvailable, captchaProvider }) => {
    if (!isAvailable) {
        return (
            <div className={classes.BigError}>
                <Error error={l('URL is now invalid')} />
            </div>
        );
    }

    const header = registrationCompleted ?
        <div className="congrats-header">
            <span className="icon"><EntypoCheck /></span> <span className="txt">{l('Confirmation')}</span>
        </div> : <div><div className="logo" /> <span>{l('Sign up to the partner program')}</span></div>;

    const contents = fatalErrorOccurred ? <Error /> : (
        <Panel id='onboarding-panel' header={header} className={classes.Onboarding}>
            <ColumnGrid>
                <Stepper container={Column}>
                    <WelcomeComponent />
                    <CompanyComponent />
                    <TermsComponent networkId={networkId} captchaProvider={captchaProvider} />
                    <Gratulations notCounted />
                </Stepper>
            </ColumnGrid>
        </Panel>
    );

    return (
        <div className={classes.SignupForm}>
            <HiddenField name="publisher.vertical" value="10" />
            <HiddenField name="publisher.network_id" value={networkId} />

            <TopBar className={classes.Topbar}><LanguageSelector/></TopBar>
            <div className="content-panel">
                {contents}
            </div>
            <Footer className={classes.Footer} />
        </div>
    );
};

Customisation.propTypes = {
    networkName: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    fatalErrorOccurred: PropTypes.bool.isRequired,
    networkId: PropTypes.string.isRequired,
    signUpCompleteUrl: PropTypes.string.isRequired,
    registrationCompleted: PropTypes.bool.isRequired,
    isAvailable: PropTypes.bool,
    captchaProvider: PropTypes.string
};

const ConnectedCustomisation = connect(
    (state, { form }) => ({
        registrationCompleted: hasSubmitSucceeded(form)(state),
        captchaProvider: state.application.captchaProvider,
    })
)(Customisation);

const OnboardingApp = withStyles(makeStyle)(ConnectedCustomisation);

OnboardingApp.PageTitle = l(':network Signup Page', { network: 'Apple Services' });

const theme = makeTheme({globalVariables: networkStyleMap}, {globalVariables: networkStyleMapOverwrite});
export default withTheme(theme)(OnboardingApp);
