// @flow
import * as React from 'react';
import { createSelector } from 'reselect';
import { Dropdown } from '@phg/stilo-toolbox/v2/component';

export const optionsToChildrenSelector = (selector: (...*) => *) => createSelector(
    selector,
    (data: Array<{ name: React.Node, value: string }>): ?Array<React.Element<typeof Dropdown.Item>> => {
        if (data) {
            return data.map(({ name, value }) => <Dropdown.Item key={value} value={value}>{name}</Dropdown.Item>);
        }

        return null;
    }
);
