// @flow

const getExistingFaviconLink = (): ?HTMLLinkElement => {
    const link = document.querySelector('link[rel="icon"]');
    if (link instanceof HTMLLinkElement) {
        return link;
    }
    return null;
};

const getDocumentHead = (): HTMLElement => {
    if (document.head instanceof HTMLElement) {
        return document.head;
    }

    const found = document.querySelector('head');
    if (found instanceof HTMLElement) {
        return found;
    }

    throw new Error('Couldn\'t find document head!');
};

export const setFavIcon = (url: string) => {
    const $favicon = getExistingFaviconLink();

    if ($favicon) {
        $favicon.href = url;
    } else {
        const $favicon = document.createElement('link');
        $favicon.rel = 'icon';
        $favicon.href = url;
        getDocumentHead().appendChild($favicon);
    }
};

export const setTitle = (title: string) => {
    document.title = title;
};