// @flow
import * as React from 'react';
import { Field } from 'redux-form';
import l from 'app/Translate';
import {
    ContactNameField,
    CountrySelectField,
    EmailAddressField,
    PhoneField,
    CompanyNameField,
    CompanyAddressField,
    PositionTitleField,
    DefaultPromotionalMethodField, ContactLocaleField, UsernameField, PasswordField, PasswordConfirmField,
} from 'app/default/field';
import Buttons from 'app/default/button';
import { notEmptyValidate } from 'app/validator/sync';
import { WebsiteField } from 'app/customisations/appleservices/field';
import SelectFieldGroup from 'app/components/Form/dropDownField';
import withFeedback from 'app/hoc/withFeedback';
const Select = withFeedback(SelectFieldGroup);

const CompanyComponent = () => (
    <form>
        <div>{l('To create an account, please create a user name and password, taking time to remember these credentials:')}</div>
        <br />

        <DefaultPromotionalMethodField />
        <ContactLocaleField />

        <UsernameField />
        <PasswordField />
        <PasswordConfirmField />

        <br />
        <div>{l('Contact Information:')}</div>
        <br />

        <ContactNameField label={l('Name')} />
        <PositionTitleField />
        <PhoneField />
        <EmailAddressField />


        <br />
        <div>{l('Company Information:')}</div>
        <br />

        <CompanyNameField label={l('Company Name')} />
        <CompanyAddressField label={l('Business Address')} />
        <CountrySelectField />
        <WebsiteField warn={{ url: notEmptyValidate }} placeholder={l('Website URL')} label={l('Website URL')} />


        <br />
        <div>{l('Select one of the following that best describes you or your business:')}</div>
        <Field
            name="publisher.meta.partner_category_vertical"
            component={Select}
            label=" "
            id="focus-select"
            options={[
                'Record Label / Management',
                'Movie Studio',
                'Book or News Publisher',
                'Ad Network',
                'App Developer',
                'Deal / Loyalty Website',
                'Content Website',
                'Other',
            ].map((val) => ({ name: l(val), value: val }))}
            warn={notEmptyValidate}
        />
        

        <Buttons back />
    </form>
);

export default CompanyComponent;
