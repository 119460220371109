export default (/*{globalVariables={}}={}*/) => ({
    ErrorPage: {
        'font-family': 'Open Sans 300',//fixme
        'font-size': '2.0em',//fixme
        'font-weight': 300,//fixme
        'text-align': 'center',
        'background': 'none',
        border: 'none',
        '& .errorPanel': {
            margin: '10px',
        },
    },
});
