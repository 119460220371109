//@flow
import application from './application';
import form from './form';
import user from './user';

export default {
    form,
    application,
    user
};
