//@flow
export const SET_USER = 'SET_USER';

export type Publisher = {|
    abn: ?string,
    account_name: ?string,
    campaign_select: ?string,
    company_division: ?string,
    company_logo: ?string,
    company_name: string,
    contact_email: ?string,
    contact_locale: ?string,
    contact_name: string,
    created: ?string,
    created_by: ?string,
    databases: [],
    default_currency: ?string,
    description: ?string,
    foreign_identifier: ?string,
    gst_registered: ?string,
    im_provider: ?string,
    im_username: ?string,
    is_affiliate_user: ?string,
    is_company: ?string,
    is_foreign_network: ?string,
    is_lead_user: ?string,
    is_us_registered: ?string,
    legal_entity: ?string,
    network_id: ?string,
    network_notes: ?string,
    network_status: ?string,
    operating_country: ?string,
    opt_in: ?string,
    payment_details: [],
    phone: ?string,
    phone_area: ?string,
    promotional_countries: [],
    promotional_method: ?number,
    promotional_method_name: ?string,
    publisher_id: string,
    reporting_identifier: ?string,
    reporting_timezone: ?string,
    signup_ip: string,
    terms_and_conditions_id: number,
    tin_number: ?string,
    uk_vat_registered: ?string,
    us_tax_state: ?string,
    vat_number: ?string,
    vertical: number,
    vertical_name: ?string,
    week_start: ?string
|};

export type User = {|
    captchaSiteKey: ?string,
    address1: ?string,
    address2: ?string,
    address3: ?string,
    address4: ?string,
    application_api_key: ?string,
    country: ?string,
    created: ?string,
    email_address: ?string,
    fax: ?string,
    firstname: ?string,
    lastname: ?string,
    locale: ?string,
    network_id: string,
    network_name: string,
    phone: ?string,
    phone_area: ?string,
    postcode: ?string,
    publisher: Publisher,
    signup_ip: ?string,
    user_api_key: ?string,
    user_id: string,
    user_name: ?string
|};

export type UserState = {|
    user: ?User
|};


const initialState: UserState = {
    user: null
};

export const setUser = (user: ?User) => {
    return {type: SET_USER, payload: user};
};

const user = (state: UserState = initialState, action: {
    type: string, payload: boolean | Error
}) => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.payload
            };
        default:
            return state;
    }
};

export {
    user as default
};
