// @flow
import * as React from 'react';
import l from 'app/Translate';
import {Dropdown as Select} from '@phg/stilo-toolbox/components/DropDown';
import FormBase from '@phg/stilo-toolbox/components/Forms/FormBaseWrapper';
import getDisplayName from 'react-display-name';
import type { InputProps } from 'redux-form/lib/FieldProps.types';

export type DropDownOptionType = {|
    name: string,
    value: string | number | boolean
|};

export type DropDownFieldPropsType = {
    id: string,
    callBack: ?(*) => void,
    input: InputProps,
    options: Array<DropDownOptionType>
};

const strigifiedArray = <C: React.ComponentType<*>>(Component: C) => {
    const StingifiedArray = ({input: {onFocus, onBlur, onChange, value, ...input}, ...props}: React.ElementConfig<C>) => {
        return (
            <Component
                {...props}
                input={{
                    ...input,
                    onFocus: (array) => onFocus(JSON.stringify([array])),
                    onBlur: (array) => onBlur(JSON.stringify([array])),
                    onChange: (array) => onChange(JSON.stringify([array])),
                    value: value ? JSON.parse(value)[0] : ''
                }}
            />
        );
    };
    StingifiedArray.displayName = `Container(${getDisplayName(Component)})`;
    return StingifiedArray;
};

const SelectField = ({options, id = '', input: {value, onFocus, onBlur, onChange}, callBack, ...props}: DropDownFieldPropsType) => {
    let currentValue = value;
    return (
        <Select
            {...props}
            id={id}
            options={options}
            value={value}
            onSelect={(e) => {
                currentValue = e;
                onChange(currentValue);
                if (callBack) {
                    callBack(currentValue);
                }
            }}
            placeholder={l('Choose...')}
            onToggle={(state) => {
                if (state) {
                    onFocus(currentValue);
                } else {
                    onBlur(currentValue);
                }
            }}
            returnSingleValue={true}
        />
    );
};

const SelectFieldGroup = (props: *): React.Element<FormBase> =>
    <FormBase Control={SelectField} {...props} formLayout="horizontal" colwidths={[6, 6]}/>;

export { strigifiedArray, SelectField, SelectFieldGroup, SelectFieldGroup as default };
