import base from 'app/default/style';
import {fromJS} from 'immutable';
import logoBlack from '../../../assets/img/network/appleservices/logo_black.svg';

export default ({globalVariables = {}} = {}) => {
    const nested = fromJS(base({globalVariables}));

    const borderRadius = {
        borderRadius: 5
    };

    const color = '#2687fb';
    return nested.mergeDeep({
        SignupForm: {
            '& .content-panel': {
                paddingTop: 20,
                paddingBottom: 140
            }
        },
        Onboarding: {
            '& .page-title': {
                lineHeight: 32
            },
            '& .logo': {
                background: 'url(' + logoBlack + ') no-repeat',
                height: 29,
                width: 25,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'inline-block',
                position: 'relative',
                top: 8
            },
            '& .ext-label .addon': {
                color: '#8a8a8a',
                fontStyle: 'italic'
            },
            '& .congrats-header': {
                textAlign: 'left',
                '& .icon': {
                    fontSize: 35,
                    color: '#6cd050',
                },
                '& .txt': {
                    fontSize: 25,
                    textAlign: 'left'
                }
            },
            '& .congrats-memo': {
                marginTop: 40,
                '& div': {
                    marginTop: 20,
                    '& a': {
                        color,
                    }
                },
                textAlign: 'center',
                fontSize: 'larger'
            },
            '& label[data-ctrl-name="label-publisher_meta_website_url"], label[data-ctrl-name="label-socialMediaUrl"]': {
                paddingTop: 0
            },
            '& .description': {
                paddingBottom: 15,
                paddingLeft: 15,
                textAlign: 'left'
            },
            '& .checks-label': {
                paddingLeft: 15,
                paddingTop: 10,
                '& .note': {
                    color: '#989898',
                    fontStyle: 'italic'
                }
            },
            '& span.tooltip-trigger': {
                color
            },
            '& .terms-box': {
                height: 333,
                border: '1px solid #e7e7e7',
                marginBottom: 20,
                fontSize: 13,
                color: '#5f5f5f',
                ...borderRadius,
                '& .header': {
                    height: 37,
                    backgroundColor: '#f7f7f7',
                    borderRadius: [5, 5, 0, 0],
                    lineHeight: '37px',
                    paddingLeft: 18
                },
                '& .content': {
                    height: 293,
                    wordBreak: 'break-word',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    overflow: 'auto',
                    padding: 18,
                    ...borderRadius
                }
            },
            '& nav i, & span.step.active': {
                background: color
            },
            '& #ContentTypesContainer, #ToolsContainer, .checks-label': {
                '& .disabled, &.disabled *': {
                    color: '#8e8e8e'
                }
            },
            '& a':{
                color
            }
        },
        Topbar: {
            '& .logo': {
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            },
        },
        Footer: {
            padding: [0, 23],
            '& a': {
                color
            },
            '&>span': {
                padding: [0, 5]
            }
        },
        '@global': {
            '.tooltip-itunes': {
                padding: [10, 5, 10, 10],
                textAlign: 'left',
                '& .h': {
                    fontWeight: 800
                },
                '& .space': {
                    height: 15
                }
            },
        },
        BigError: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%'
        }
    }).toJS();
};
