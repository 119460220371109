//@flow
import * as React from 'react';
import {l} from 'app/Translate';
import PropTypes from 'prop-types';
import Link from '@phg/stilo-toolbox/components/Links/Link';

export type ErrorsMetaType = {
    id: string,
    touched: boolean,
    error: ?string,
    warning: ?string,
    networkError: string,
    meta: {form: string}
}; 
    

const ErrorsComponentWithRetry = ({
    id, touched, error, warning, networkError, meta: {form}
}: ErrorsMetaType, context: *) => {
    return (
        <div className="col-xs-6 col-md-6 error col-xs-push-6 col-md-push-0">
            {touched && (
                (networkError && 
                    <span className="errorText" id={id + 'Error'}>
                        {l(networkError)}
                        {' '}
                        <Link
                            active={false}
                            onClick={() => {
                                context._reduxForm.dispatch({type: 'RESET_FIELD_SERVER_ERROR'});
                                context._reduxForm.asyncValidate(form, id);
                            }}
                        >
                            {l('Retry')}
                        </Link>
                    </span>
                )
                ||
                (error && <span className="errorText" id={id + 'Error'}>{l(error)}&lrm;</span>)
                ||
                (warning && <span className="warningText" id={id + 'Warning'}>{l(warning)}&lrm;</span>)
            )}
        </div>
    );
};

ErrorsComponentWithRetry.propTypes = {
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
    warning: PropTypes.string,
    id: PropTypes.string.isRequired,
    meta: PropTypes.object
};

ErrorsComponentWithRetry.contextTypes = {
    _reduxForm: PropTypes.any
};

export {
    ErrorsComponentWithRetry, ErrorsComponentWithRetry as default
};
