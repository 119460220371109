import * as React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@phg/stilo-toolbox/components/Forms/Checkbox';

export const values2indexes = (options, values) => {
    if (!options || !Array.isArray(values)) return [];

    const tmpValues = values.map((v) => JSON.stringify(v));

    return options.map((opt, i) =>({...opt, i}))
        .filter((opt): boolean => tmpValues.indexOf(JSON.stringify(opt.value)) >= 0)
        .map((opt): number => opt.i);
};

type StringifiedGroupPropTypes = {
    input: {
        onFocus: ([]) => string,
        onBlur: ([]) => string,
        onChange: ([]) => string,
        value: ([]) => string
    }
};

const strigifiedGroup = (Component) => {
    const StringifiedGroup = ({input: {onFocus, onBlur, onChange, value, ...input}, ...props}: StringifiedGroupPropTypes) => {
        return (
            <Component
                {...props}
                input={{
                    ...input,
                    onFocus: (array) => onFocus(JSON.stringify(array)),
                    onBlur: (array) => onBlur(JSON.stringify(array)),
                    onChange: (array) => onChange(JSON.stringify(array)),
                    value: value ? JSON.parse(value) : []
                }}
            />
        );
    };
    StringifiedGroup.displayName = `StringifiedGroup(${Component.displayName || 'Component'})`;
    return StringifiedGroup;
};

class CheckBoxGroup extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        input: PropTypes.object.isRequired,
        meta: PropTypes.object,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        selectAll: PropTypes.bool,
        selectAllLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        type: PropTypes.string,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.node.isRequired,
                value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
            })
        ).isRequired,
        disabled: PropTypes.bool
    };

    static defaultProps = {
        selectAllLabel: 'Select all...'
    };

    static getDerivedStateFromProps(props) {
        return {
            selected: values2indexes(props.options, props.input.value)
        };
    }
    
    state = CheckBoxGroup.getDerivedStateFromProps(this.props);
    
    fireEventsOnValueChange = (values, oldValue) => {
        this.props.input.onFocus && this.props.input.onFocus(oldValue);
        this.props.input.onChange(values);
        this.props.input.onBlur && this.props.input.onBlur(values);
        this.props.input.callBack && this.props.input.callBack(this.props.id);
    };

    setSelectedValues = (values) => {
        const selectedValues = values.map((i) => (this.props.options[i].value));
        const oldValue = this.state.selected.map((i) => (this.props.options[i].value));

        this.setState(
            { ...this.state, selected: values },
            () => this.fireEventsOnValueChange(selectedValues, oldValue)
        );
    };

    handleChange = (index, checked) => {
        const selected = this.state.selected.filter((i) => i !== index);
        if (checked) {
            selected.push(index);
        }

        this.setSelectedValues(selected);
    };

    handleSelectAll = (e) => {
        if (e.target.checked) {
            this.setSelectedValues(this.props.options.map((_, i) => i));
        } else {
            this.setSelectedValues([]);
        }
    };

    render() {
        const { label, selectAll, selectAllLabel, input, options, disabled, ...props } = this.props;
        delete props.meta;
        delete props.callBack;

        return (
            <div {...props}>
                {label && (<span>{label}</span>)}
                {selectAll && (
                    <Checkbox
                        key={`cbg-${input.name}-select-all`}
                        handleChange={this.handleSelectAll}
                        label={selectAllLabel}
                        type="checkbox"
                        id={`cbg-${input.name}-select-all`}
                        checked={options.length === this.state.selected.length}
                        disabled={disabled}
                    />
                )}
                {options.map(({ label, value }, index) =>
                    <Checkbox
                        key={`cbg-${input.name}-${value}`}
                        handleChange={(e) => this.handleChange(index, e.target.checked)}
                        label={label}
                        type="checkbox"
                        id={`cbg-${input.name}-${index}`}
                        checked={this.state.selected.includes(index)}
                        disabled={disabled}
                    />
                )}
            </div>
        );
    }
}

export { strigifiedGroup, CheckBoxGroup, CheckBoxGroup as default };
