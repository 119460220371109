// @flow
import { immutableDeepMerge } from '@phg/stilo-toolbox/utils';

export type BuiltActionNames<N> = $ReadOnly<{[name: N]: string}>;

export type ActionCreatorsFactorySig = (BuiltActionNames<*>) => {[string]: (...*) => *};

export type ReducersType<S, N> = {+[name: N]: (S, *) => S };

export type ReducersFactorySig = <S>(BuiltActionNames<*>, S) => ReducersType<S, *>;

export type DuckConfigType<S> = {
    mountPoint: string,
    actionTypesNames: string[],
    actionCreatorsFactory: ActionCreatorsFactorySig,
    selectors: {
        [string]: (S, ...*) => *
    },
    reducersFactory: ReducersFactorySig,
    initialState: $ReadOnly<S>
};

export type DuckType = {
    mountPoint: string,
    actionTypes: BuiltActionNames<string>,
    actionCreators: {
        [string]: (...*) => *
    },
    selectors: {
        [string]: (...*) => *
    },
    reducer: <S>(S, *) => S
};

export const makeActionTypes = <N: string>(id: string, actionTypes: Array<N>): BuiltActionNames<N> => {
    return actionTypes.reduce((acc: {[name: N]: string}, key: N) => {
        const actionType = `${id}:${key}`;
        acc[key] = actionType.toUpperCase();
        return acc;
    }, {});
};

export const makeReducer = <S, N: string>(reducers: ReducersType<S, N>, initialState: S) => {
    // $FlowIgnore
    return (state: S = initialState, action: *): S => {
        if (reducers.hasOwnProperty(action.type)) {
            return reducers[ action.type ](state, action);
        }

        return state;
    };
};

export const makeDuck = <S: {}>({ 
    mountPoint, 
    actionTypesNames, 
    actionCreatorsFactory,
    selectors,
    reducersFactory,
    initialState
}: DuckConfigType<S>): DuckType => {
    if (typeof mountPoint !== 'string' || mountPoint.length == 0) {
        throw new Error('Invalid mount point `' + mountPoint + '` provided!');
    }
    
    // $FlowIgnore
    const actionTypes = makeActionTypes(mountPoint, actionTypesNames);
    
    const result = {
        mountPoint,
        actionTypes,
        actionCreators: actionCreatorsFactory(actionTypes),
        selectors: Object.entries(selectors).reduce((acc, [name, selector]) => {
            acc[name] = function (store, ...args) { return selector(store[mountPoint], ...args); };
            return acc;
        }, {}),
        reducer: makeReducer(reducersFactory(actionTypes, initialState), initialState)
    };
    
    for (const [key, creator] of Object.entries(result.actionCreators)) {
        // $FlowIgnore
        result.actionCreators[key] = creator.bind(result);
    }
    
    return result;
};

// eslint-disable-next-line complexity
export const composeDuck = <S>(...duckConfigs: Array<$Shape<DuckConfigType<S>>>): * => {
    let mountPoint = '';
    const actionNamesBuffer = new Set;
    const actionCreatorsBuffer = [];
    const reducersFactoryBuffer = [];
    const selectors = {};
    let initialState = {};
    
    for (const config of duckConfigs) {
        if (config.mountPoint) {
            mountPoint = config.mountPoint;
        }
        
        (config.actionTypesNames || []).forEach((value) => actionNamesBuffer.add(value));
        
        if (config.actionCreatorsFactory) {
            actionCreatorsBuffer.push(config.actionCreatorsFactory);
        }
          
        if (config.selectors) {
            Object.assign(selectors, config.selectors);
        }
        
        if (config.reducersFactory) {
            reducersFactoryBuffer.push(config.reducersFactory);
        }
        
        if (config.initialState) {
            initialState = immutableDeepMerge(initialState, config.initialState);
        }
    }

    return makeDuck({
        mountPoint,
        actionTypesNames: Array.from(actionNamesBuffer),
        actionCreatorsFactory(actionTypes) {
            return actionCreatorsBuffer.reduce((acc, actionCreatorsFactory) => {
                return Object.assign(acc, actionCreatorsFactory(actionTypes));
            }, {});
        },
        reducersFactory(actionTypes, initialState) {
            return reducersFactoryBuffer.reduce((acc, reducersFactory) => {
                return Object.assign(acc, reducersFactory(actionTypes, initialState));
            }, {});
        },
        selectors,
        initialState
    });
};

export default makeDuck;
