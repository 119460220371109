//@flow 
export const STEPPER_SET_STEP = 'STEPPER_SET_STEP';
export const ADD_ERROR = 'ADD_ERROR';
export const SET_CAPTCHA_KEY = 'SET_CAPTCHA_KEY';
export const SET_CAPTCHA_PROVIDER = 'SET_CAPTCHA_PROVIDER';

type Error = {|
    reason: string,
    time: Date
|};

export type ApplicationState = {|
    captchaSiteKey: ?string,
    captchaProvider: ?string,
    hasErrored: boolean,
    errors: Array<Error>,
    currentStep: number
|};

const initialState: ApplicationState = {
    captchaSiteKey: null,
    captchaProvider: null,
    hasErrored: false,
    errors: [],
    currentStep: 0
};

export const addError = (time: Date, reason: string) => {
    return {type: ADD_ERROR, payload: {time, reason}};
};

export const setCaptchaKey = (key: ?string) => {
    return {type: SET_CAPTCHA_KEY, payload: key};
};
export const setCaptchaProvider = (provider: ?string) => {
    return {type: SET_CAPTCHA_PROVIDER, payload: provider};
};

// eslint-disable-next-line complexity
const application = (state: ApplicationState = initialState, action: {
    type: string, payload: boolean | Error
}) => {
    switch (action.type) {
        case SET_CAPTCHA_KEY:
            return {
                ...state,
                captchaSiteKey: action.payload
            };
        case SET_CAPTCHA_PROVIDER:
            return {
                ...state,
                captchaProvider: action.payload
            };
        case STEPPER_SET_STEP:
            return {
                ...state,
                currentStep: action.payload
            };
        case ADD_ERROR:
            return {
                ...state,
                hasErrored: true,
                errors: [...state.errors, action.payload]
            };
        default:
            return state;
    }
};

export {
    application as default
};
