// @flow
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import createField from 'app/hoc/createField';
import {
    notEmptyValidate
} from 'app/validator/sync';
import componentMount from 'app/hoc/componentMount';

import * as api from 'app/api';
import l, { translateSelectOptions } from 'app/Translate';
import { Select } from './base';
import { focusOfContent } from './name';

const verticalsSelector = createSelector(
    api.vertical.selectors.selectData,
    api.locale.selectors.selectCurrentLanguage,
    translateSelectOptions
);

const FocusOfContentSelectField = createField(focusOfContent, () => ({
    id: 'focus-select',
    label: l('Vertical'),
    warn: notEmptyValidate
}))(Select);

export default connect(
    (state) => ({
        options: verticalsSelector(state)
    }),
    (dispatch) => ({
        onMountLoadAction() {
            dispatch(api.vertical.actionCreators.fetchAction());
        }
    })
)(componentMount(FocusOfContentSelectField));
