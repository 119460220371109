// @flow
import * as React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

export default class Recaptcha extends React.Component<*> {
    componentDidMount() {
        if (this.props.onChange) {
            this.props.onChange(null);
        }
    }

    render() {
        // eslint-disable-next-line no-unused-vars
        const { value, wrapperClassName, ...props } = this.props;

        return React.createElement(ReCAPTCHA, props);
    }
}
