// @flow
import * as React from 'react';
import l from 'app/Translate';
import Buttons from 'app/default/button';

const WelcomeComponent = () => (
    <form>
        <div>
            {l('Thank you for signing up for the Apple Services Performance Partners Program.')}
        </div>
        <br />

        <div>
            {l('More than just a traditional affiliate program, ' +
                'our program provides unique opportunities for our valued partners ' +
                'and provides many ways for your website or app to link to millions of songs and albums, ' +
                'as well as books, audiobooks, movies and TV shows.')}
        </div>
        <br />

        <div>
            {l('Please complete the form below to begin set up of your account on Partnerize, our program platform.')}
        </div>
        <br />

        <Buttons />
    </form>
);

export default WelcomeComponent;
