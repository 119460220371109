//@flow
import { makeReadOnlyApi } from 'app/api/apiFactory';
import { sortOptions } from 'app/helpers/object';

import type { Dispatch } from 'redux';

const fetchAsync = (api) => (dispatch: Dispatch<*>, getState: () => *, { client }) => {
    return client({
        dispatch,
        url: '/api/reference/website_type',
        responseHandler(response) {
            const websiteTypes = response.data.website_types && response.data.website_types.map((item)=> {
                return item.website_type;
            }) || [];

            websiteTypes.sort(sortOptions);

            dispatch(api.actionCreators.successAction(websiteTypes));
        },
    });
};

export default makeReadOnlyApi('website_type', {
    actionCreatorsFactory: ({ FETCH_ASYNC }) => ({
        fetchAction() {
            return ({
                type: FETCH_ASYNC,
                payload: { actionCreator: fetchAsync },
                meta: { remote: { ttl: Infinity, concurrent: false }, api: this }
            });
        }
    })
});


