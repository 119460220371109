//@flow
import { makeReadOnlyApi } from 'app/api/apiFactory';
import type { Dispatch } from 'redux';

const fetchAsync = (api, { language, countryIso, networkId }) => (dispatch: Dispatch<*>, getState: () => *, { client }) => {
    return client({
        dispatch,
        url: `/api/reference/terms/${language}/${countryIso}/${networkId}`,
        responseHandler(response) {
            const terms = response.data.terms.map(({ term }) => term);
            dispatch(api.actionCreators.successAction(terms));
        }
    });
};

export default makeReadOnlyApi('terms', {
    actionCreatorsFactory: ({ FETCH_ASYNC }) => ({
        fetchAction(language, countryIso, networkId) {
            return ({
                type: FETCH_ASYNC,
                payload: { actionCreator: fetchAsync, props: { language, countryIso, networkId } },
                meta: {
                    remote: { 
                        ttl: Infinity,
                        concurrent: false, 
                        parametric: ({ language, countryIso, networkId }) => language + '.' + countryIso + '.' + networkId 
                    },
                    api: this
                }
            });
        }
    })
});


