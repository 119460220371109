//@flow
import { post } from 'app/connect';
import { SubmissionError } from 'redux-form';
import { immutableDeepMerge } from '@phg/stilo-toolbox/utils';
import { filterObject } from '@phg/stilo-utils/Objects';
import type { Dispatch } from 'redux';

import { setUser } from 'app/state/reducers/user';
import {l} from '../Translate';
import {STEPPER_SET_STEP} from '../state/reducers/application';

const omitTempFields = filterObject((_, key: string) => !(key.startsWith('__') && key.endsWith('__')));

const createSubmit = (postHandler: typeof post) => (values: {}, dispatch: Dispatch<*>) => {
    dispatch({ type: 'SEND_FORM', payload: values });

    const params = immutableDeepMerge(
        {
            signup_ip: '',//!!
            publisher: {
                uk_vat_registered: 'n',
                signup_ip: '',//!!
            }
        },
        omitTempFields(values)
    );

    return postHandler('/api/user', params).then(
        (data) => {
            if (data.status === 200 && data.data && data.data.user && data.data.user.user_id) {
                dispatch(setUser(data.data.user));
                return true;
            }

            throw new SubmissionError({ _error: 'submit error', data});
        }
    ).catch( error => {
        // eslint-disable-next-line max-len
        let fields = error && error.errors && error.errors.data && error.errors.data.data && error.errors.data.data.error ? error.errors.data.data.error.fields : undefined;
        if (!fields)
            fields = error && error.response && error.response.data && error.response.data.error ? error.response.data.error.fields : {};
        const emailError = Boolean(fields.email_address || fields.username_and_email);
        const usernameError = Boolean(fields.user_name || fields.username_and_email);
        const errors = {};
        if ( usernameError ) {
            errors.user_name = l('That username is taken');
        }
        if ( emailError ) {
            errors.email_address = l('Email address is in use by another Partnerize account. Please use a different email address.');
        }
        if ( usernameError || emailError ) {
            const jumpTo = window.location.pathname.includes('appleservices') ? 1 : 0;
            dispatch({type: STEPPER_SET_STEP, payload: jumpTo});
        }
        errors._error = 'submit error';
        throw new SubmissionError(errors);
    });
};
const submit = createSubmit(post);

export {
    submit as default, submit, createSubmit
};
