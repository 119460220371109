// @flow
import { withFieldWrapper } from '@phg/stilo-toolbox/v2/hoc';
import { Input as StiloInput, Dropdown } from '@phg/stilo-toolbox/v2/component';
import withReduxFormWrapper, { withDropdownAdapter } from 'app/hoc/withReduxFormWrapper';
import withTetheredTooltip from 'app/hoc/withTetheredTooltip';
import DefaultValueFields from 'app/components/Form/defaultValue';
import ReCAPTCHA from '../component/Recaptcha';
import HCAPTCHA from '../component/Hcaptcha';

export const Input = withReduxFormWrapper(withFieldWrapper(withTetheredTooltip(StiloInput)));
export const UsernameInput = Input;
export const Select = withReduxFormWrapper(withDropdownAdapter(withFieldWrapper(Dropdown)));
export const Hidden = DefaultValueFields;

// eslint-disable-next-line no-unused-vars
export const Recaptcha = withReduxFormWrapper(ReCAPTCHA);
export const Hcaptcha = withReduxFormWrapper(HCAPTCHA);
