// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { parseUrl } from 'app/helpers/url';
import createField from 'app/hoc/createField';
import { notEmptyValidate } from 'app/validator/sync';
import { Hcaptcha as BaseHcaptcha } from './base';
import { hcaptcha } from './name';

const Hcaptcha = createField(hcaptcha, () => ({
    id: 'Hcaptcha',
    validate: notEmptyValidate
}))(BaseHcaptcha);

const ConditionalCaptchaField = (props) => props.sitekey ? React.createElement(Hcaptcha, props) : null;

export default connect(
    (state) => ({
        sitekey: state.application.captchaSiteKey,
        apihost: 'https://cn1.hcaptcha.com',
        languageOverride: parseUrl(location.pathname).locale
    })
)(ConditionalCaptchaField);
