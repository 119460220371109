// @flow
import createField from 'app/hoc/createField';
import {
    notEmptyValidate
} from 'app/validator/sync';
import l from 'app/Translate';
import { Input } from './base';
import { postcode } from './name';

const PostCodeField = createField(postcode, () => ({
    id: 'PostCode',
    type: 'text',
    label: l('Post/Zip code'),
    placeholder: l('Enter'),
    warn: notEmptyValidate
}))(Input);

export default PostCodeField;
