//@flow
import * as React from 'react';
import {l} from 'app/Translate';
import PropTypes from 'prop-types';

export type ErrorsMetaType = {
    id: string,
    touched: boolean,
    error: ?string,
    warning: ?string
};

const ErrorsComponent = ({id, touched, error, warning}: ErrorsMetaType) => {
    return (
        <div className="col-xs-6 col-md-6 error col-xs-push-6 col-md-push-0">
            {touched && (
                (error && <span className="errorText" id={id + 'Error'}>{l(error)}&lrm;</span>)
            || (warning && <span className="warningText" id={id + 'Warning'}>{l(warning)}&lrm;</span>
            ))}
        </div>
    );
};

ErrorsComponent.propTypes = {
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
    warning: PropTypes.string,
    id: PropTypes.string.isRequired,
};

export {
    ErrorsComponent, ErrorsComponent as default, ErrorsComponent as renderField
};