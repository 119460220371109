const isString = <T>(value: T): boolean => typeof value === 'string' || value instanceof String;

let _locales = null;
export const setLocale = locales => {
    _locales = locales;
};

const checkTranslationIsReady = locales => {
    if ( locales === null &&  process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.warn('Trying to translate before locales are ready. Hint: You can use a thunk to delay the execution.');
    }

    // this is just a warning, so we set an empty dictionary if we don't have one, and continue
    return locales || {};
};

export function t(locales, key, params, situation, context) {
    locales = checkTranslationIsReady(locales);

    let res = findInLocales(locales, key, situation, context);

    if (params) {
        for (const p in params) {
            res = res.replace(new RegExp(':' + p, 'g'), params[p]);
        }
    }
    return res;
}

export const translate = (key, params, situation, context, locales = _locales) => {
    return t(locales, key, params, situation, context);
};
export const l = translate;
export default translate;

/* for translating strings with inline jsx elements */
export const tWithElements = (locales, key, params, situation, context) => {
    const res = findInLocales(locales, key, situation, context);

    return res.split(/(:[\w-]+)/)
        .filter((x)=> x.length > 0)
        .map((item)=> (item.startsWith(':') && params[item.slice(1)]) ? params[item.slice(1)] : item);
};

export const translateWithElements = (key, params, situation, context, locales = _locales) => {
    return tWithElements(locales, key, params);
};

/*eslint complexity: ["error", 100]*/
export function findInLocales(locales, key, situation = 'default', context) {
    locales = checkTranslationIsReady(locales);

    let entry = locales[key];

    if (entry === undefined) {
        return key;
    }

    if (isString(entry)) {
        return entry;
    }

    situation = entry[situation] === undefined ? 'default' : situation;

    if (entry[situation] === undefined) {
        return key;
    }

    entry = entry[situation];

    if (isString(entry)) {
        return entry;
    }

    context = entry[context] === undefined ? 'other' : context;

    return entry[context] === undefined ? key : entry[context];
}

export const translateSelectOptions = (options)=> (options || []).map(({ name, value }) => ({ name: l(name), value }));
