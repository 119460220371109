// @flow
import { connect } from 'react-redux';
import actions from 'redux-form/lib/actions';
import { createSelector } from 'reselect';
import createField from 'app/hoc/createField';
import { notEmptyValidate, notANumberValidate } from 'app/validator/sync';
import componentMount from 'app/hoc/componentMount';

import * as api from 'app/api';
import l, { translateSelectOptions } from 'app/Translate';
import { Select } from './base';
import { country, entityTermsAndConditions } from './name';

const countriesSelector = createSelector(
    api.country.selectors.selectData,
    api.locale.selectors.selectCurrentLanguage,
    translateSelectOptions
);

const CountrySelectField = createField(country, () => ({
    id: 'countrySelect',
    label: l('Country'),
    validate: notANumberValidate,
    warn: notEmptyValidate,
    onChange() {
        entityTermsAndConditions.forEach((field) => this.meta.dispatch(actions.change(this.meta.form, field, '', false, false)));
    }
}))(Select);

export default connect(
    (state) => ({
        options: countriesSelector(state)
    }),
    (dispatch) => ({
        onMountLoadAction() {
            dispatch(api.country.actionCreators.fetchAction());
        }
    })
)(componentMount(CountrySelectField));
