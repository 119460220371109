// @flow
import createField from 'app/hoc/createField';
import {
    notEmptyValidate,
    sameFieldValidate,
    emailValidate
} from 'app/validator/sync';

import l from 'app/Translate';
import { Input } from './base';
import { emailAddress } from './name';

const EmailAddressField = createField(emailAddress, () => ({
    id: 'EmailAddress',
    type: 'email',
    label: l('Email Address'),
    validate: emailValidate,
    warn: notEmptyValidate
}))(Input);
export default EmailAddressField;

export const EmailAddressConfirmField = createField('__confirm_email_address__', () => ({
    id: 'ConfirmEmailAddress',
    type: 'email',
    label: l('Confirm Email Address'),
    validate: sameFieldValidate('email_address', l('Ensure email addresses match')),
    warn: notEmptyValidate
}))(Input);
