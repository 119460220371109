//@flow
import { makeReadOnlyApi } from 'app/api/apiFactory';
import type { Dispatch } from 'redux';

const fetchAsync = (api, { advertiserId }) => (dispatch: Dispatch<*>, getState: () => *, { client }) => {
    return client({
        dispatch,
        url: `/api/signup/campaign/${advertiserId}`,
        responseHandler(response) {
            const campaigns = response.data.campaigns.map(({ campaign: { campaign_id, title, terms } }) => ({ campaign_id, title, terms }));
            dispatch(api.actionCreators.successAction(campaigns));
        }
    });
};

export default makeReadOnlyApi('campaign', {
    actionCreatorsFactory: ({ FETCH_ASYNC }) => ({
        fetchAction(advertiserId) {
            return ({
                type: FETCH_ASYNC,
                payload: { actionCreator: fetchAsync, props: { advertiserId } },
                meta: {
                    remote: { 
                        ttl: Infinity,
                        concurrent: false, 
                        parametric: ({ advertiserId }) => advertiserId 
                    },
                    api: this
                }
            });
        }
    })
});
