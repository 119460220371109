// @flow
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import cx from 'classname';
import Overlay from '@phg/stilo-toolbox/v2/component/Overlay';
import { withStyles } from '@phg/stilo-toolbox/components/ThemeProvider/index';
import makeStyle from './Modal.style';

type ModalProps = {
    title: string,
    onClickClose?: () => void,
    isOpen?: boolean,
    content: string,
    classes: {
        Modal: string
    },
    className?: string
};


class Modal extends React.Component<ModalProps> {
    el: HTMLDivElement = document.createElement('div');

    componentDidMount() {
        // The portal element is inserted in the DOM tree after
        // the Modal's children are mounted, meaning that children
        // will be mounted on a detached DOM node. If a child
        // component requires to be attached to the DOM tree
        // immediately when mounted, for example to measure a
        // DOM node, or uses 'autoFocus' in a descendant, add
        // state to Modal and only render the children when Modal
        // is inserted in the DOM tree.
        document.body && document.body.appendChild(this.el);
    }

    componentWillUnmount() {
        document.body && document.body.removeChild(this.el);
    }

    render() {
        const { classes, className, title, content, isOpen, ...rest } = this.props;

        return isOpen ? ReactDOM.createPortal(
            <Overlay
                {...rest}
                className={cx(classes.Modal, className)}
                overlayTitle={title}
                isOpen
            >
                <p
                    dangerouslySetInnerHTML={{
                        __html: content
                    }}
                />
            </Overlay>,
            this.el
        ) : null;
    }
}

export default withStyles(makeStyle)(Modal);
