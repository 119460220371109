//@flow
import { makeReadOnlyApi } from 'app/api/apiFactory';
import { sortOptions } from 'app/helpers/object';

import type { Dispatch } from 'redux';

const fetchAsync = (api) => (dispatch: Dispatch<*>, getState: () => *, { client }) => {
    return client({
        dispatch,
        url: '/api/reference/country',
        responseHandler(response) {
            const countries = response.data.countries.map(({ country: { printable_name: name, iso: value } }) => ({ name, value }));

            countries.sort(sortOptions);

            dispatch(api.actionCreators.successAction(countries));
        },
    });
};

export default makeReadOnlyApi('country', {
    actionCreatorsFactory: ({ FETCH_ASYNC }) => ({
        fetchAction() { 
            return {
                type: FETCH_ASYNC,
                payload: { actionCreator: fetchAsync },
                meta: { remote: { ttl: Infinity, concurrent: false }, api: this },
            };
        }
    }),
});
