import 'babel-polyfill';
import React from 'react';
import {render} from 'react-dom';
import App from 'app';
import Customisation from 'app/customisations/appleservices';

const CustomizedApp = App(
    Customisation,
    window.Config.network,
    window.Config.captchaSiteKey,
    window.Config.isAvailable,
    window.Config.captchaProvider
);
render(<CustomizedApp signUpCompleteUrl={window.Config.url.signUpComplete} />, document.getElementById('app'));
