// @flow
import * as React from 'react';
import Modal from 'app/components/modal';

type ModalTriggerProps = {
    children: React.Element<React.ComponentType<{ onClick: (...*) => * }>>,
    title: string,
    content: string
};

export default
class ModalTrigger extends React.Component<ModalTriggerProps, { open: boolean }> {
    state = {
        open: false
    };

    handleClose = () => this.setState({ open: false });

    onClick = (...args: Array<*>) => {
        this.setState({ open: true });
        if (this.props.children.props.onClick) {
            this.props.children.props.onClick(...args);
        }
    };

    render() {
        const trigger = React.Children.only(this.props.children);
        return (
            <React.Fragment>
                {React.cloneElement(trigger, { onClick: this.onClick })}
                <Modal
                    title={this.props.title}
                    close={this.handleClose}
                    isOpen={this.state.open}
                    content={this.props.content.replace(/\n/g, '<br />')}
                />
            </React.Fragment>
        );
    }
}
