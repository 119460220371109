import * as React from 'react';
import PropTypes from 'prop-types';
import {l} from 'app/Translate';
import {parseUrl} from 'app/helpers/url';

const conditions = {
    'default': 'https://docs.partnerize.com/terms_and_conditions/apple_services/2.0/UK/EN.pdf',
    'jp': 'https://docs.partnerize.com/terms_and_conditions/apple_services/1.9/JP/JP.pdf',
    'pt_br': 'https://docs.partnerize.com/terms_and_conditions/apple_services/1.9/PT_BR/PT_BR.pdf',
    'de': 'https://docs.partnerize.com/terms_and_conditions/apple_services/1.9/DE/DE.pdf',
    'es': 'https://docs.partnerize.com/terms_and_conditions/apple_services/1.9/ES/ES.pdf',
    'fr': 'https://docs.partnerize.com/terms_and_conditions/apple_services/1.9/FR/FR.pdf',
    'it': 'https://docs.partnerize.com/terms_and_conditions/apple_services/1.9/IT/IT.pdf',
    'zh_cn': 'https://docs.partnerize.com/terms_and_conditions/apple_services/1.9/ZH_CN/ZH_CN.pdf',
    'zh_hk': 'https://docs.partnerize.com/terms_and_conditions/apple_services/1.9/ZH_HK/ZH_HK.pdf'
};

const Footer = ({ className }: { className: string }) => {
    const locale = parseUrl(window.location.pathname).locale;

    return (
        <div className={className}>
            <a href="https://itunesaffiliate.phgsupport.com/hc/en-us">
                <span>{l('Contact Us')}</span>
            </a>
            <span>|</span>
            <a href="https://affiliate.itunes.apple.com/resources/">
                <span>{l('Partner Resources')}</span>
            </a>
            <span>|</span>
            <a href={conditions[locale] || conditions['default']}>
                <span>{l('Partnerize Terms and Conditions')}</span>
            </a>
        </div>
    );
};

Footer.propTypes = {
    className: PropTypes.string.isRequired
};

export { Footer as default, Footer };
