//@flow
import axios from 'axios';

const get = (url: string, params: {} = {}): * =>
    axios.get(url, {
        timeout: 15000,
        ...params
    });

export {
    get as default, get
};
