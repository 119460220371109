// @flow
import createField from 'app/hoc/createField';
import {
    notEmptyValidate
} from 'app/validator/sync';
import l  from 'app/Translate';
import { Input } from './base';
import { addressFirst } from './name';

const AddressFirstField = createField(addressFirst, () => ({
    id: 'AddressFirst',
    type: 'text',
    label: l('Company address'),
    placeholder: l('Enter'),
    warn: notEmptyValidate
}))(Input);

export default AddressFirstField;
