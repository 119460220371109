// @flow
import * as React from 'react';
import getDisplayName from 'react-display-name';
import { Field } from 'redux-form';
import getIn from 'redux-form/lib/structure/plain/getIn';
import { filterProps } from '@phg/stilo-utils/Objects';

import type { MultiNameFieldType } from './types';

export const createOneToRuleThemAll = (Component: React.ElementType, names: MultiNameFieldType, onlyTouchedFeedback?: boolean) => {
    const blacklist = Array.from(names.reduce((acc, name) => {
        const prefix = name.split('.')[0];
        if (prefix) {
            acc.add(prefix);
        }
        
        return acc;
    }, new Set(['_onChange', '_onVerify', '_onBlur', '_onFocus', '_onDrop', '_onDragStart', '_warn', '_validate'])));
    
    const cleanProps = filterProps(blacklist);
        
    class OneToRuleThemAll extends React.Component<*, *> {
        static displayName = `OneToRuleThemAll(${getDisplayName(Component)})`;
        
        callOnAllFields = (path: string, ...args: Array<*>) => {
            for (const name of names) {
                const fn = getIn(this.props, name + '.' + path);
                if (typeof fn === 'function') {
                    fn(...args);
                }
            }
        };

        pickValue = (props: *, path: string, check?: string) => {
            for (const name of names) {
                const value = getIn(props, name + '.' + path);
                if (value) {
                    if (check && !getIn(props, name + '.' + check)) {
                        continue;
                    }

                    return value; 
                }
            }
        };
        
        onFocus = (event: *) => {
            this.callOnAllFields('input.onFocus', event);
            if (this.props._onFocus) {
                this.props._onFocus.call(this._props, event);
            }
        };
        
        onBlur = (valueOrEvent: *) => {
            this.callOnAllFields('input.onBlur', valueOrEvent);
            if (this.props._onBlur) {
                this.props._onBlur.call(this._props, valueOrEvent);
            }
        };
        
        onChange = (valueOrEvent: *) => {
            this.callOnAllFields('input.onChange', valueOrEvent);
            if (this.props._onChange) { 
                this.props._onChange.call(this._props, valueOrEvent);
            }
        };
        
        onVerify = (valueOrEvent: *) => {
            this.callOnAllFields('input.onVerify', valueOrEvent);
            if (this.props._onVerify) { 
                this.props._onVerify.call(this._props, valueOrEvent);
            }
        };
        
        onDragStart = (event: *) => {
            this.callOnAllFields('input.onDragStart', event);
            if (this.props._onDragStart) {
                this.props._onDragStart.call(this._props, event);
            }
        };
        
        onDrop = (event: *) => {
            this.callOnAllFields('input.onDrop', event);
            if (this.props._onDrop) {
                this.props._onDrop.call(this._props, event);
            }
        };
        
        _generateProps = (props: *) => {
            const referenceField = getIn(props, names[0]);

            return Object.assign(cleanProps(props), {
                input: {
                    onFocus: this.onFocus,
                    onChange: this.onChange,
                    onVerify: this.onVerify,
                    onBlur: this.onBlur,
                    onDragStart: this.onDragStart,
                    onDrop: this.onDrop,
                    value: referenceField.input.value,
                    checked: referenceField.input.checked,
                },
                meta: {
                    ...referenceField.meta,
                    error: this.pickValue(props, 'meta.error', onlyTouchedFeedback ? 'meta.touched' : undefined),
                    warning: this.pickValue(props, 'meta.warning', onlyTouchedFeedback ? 'meta.touched' : undefined)
                }
            });
        };

        _props = this._generateProps(this.props);

        componentWillReceiveProps(props: *) {
            if (props !== this.props) {
                this._props = this._generateProps(props);
            }
        }
        
        render() {
            const element = React.createElement(Component, this._props);
            if ('_warn' in this.props || '_validate' in this.props) {
                return (
                    <React.Fragment>
                        {element}
                        <Field 
                            name={names[0]} 
                            warn={this.props._warn} 
                            validate={this.props._validate} 
                            component={(): ?React.Element<*> => null}
                        />
                    </React.Fragment>
                );
            }
            
            return element;
        }
    }
    
    return OneToRuleThemAll;
};

export default createOneToRuleThemAll;
