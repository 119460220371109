// @flow
import * as React from 'react';
import Checkbox from '@phg/stilo-toolbox/components/Forms/Checkbox';
import type { InputProps } from 'redux-form/lib/FieldProps.types';

export type TextFieldPropsType = {
    id: string,
    label?: React.Node,
    input: InputProps,
    checkedValue: mixed,
    uncheckedValue: mixed,
    style?: $Shape<CSSStyleDeclaration>
};

const CheckboxComponent = ({
    input: { onFocus, onBlur, value, onChange, ...input },
    label, id = '', checkedValue, uncheckedValue, style
}: TextFieldPropsType) => (
    <Checkbox
        {...input}
        style={style}
        handleChange={(e) => {
            onFocus && onFocus(e);
            if (e.target.checked) {
                onChange(checkedValue);
                onBlur && onBlur(checkedValue);
            } else {
                onChange(uncheckedValue);
                onBlur && onBlur(uncheckedValue);
            }
        }}
        label={label}
        type="checkbox"
        id={id}
        checked={value === checkedValue}
        className={'label' + id}
    />
);

CheckboxComponent.defaultProps = {
    checkedValue: true,
    uncheckedValue: false
};

export default CheckboxComponent;