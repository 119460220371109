// @flow
import * as React from 'react';
import getDisplayName from 'react-display-name';
import cx from 'classname';
import { withStyles } from '@phg/stilo-toolbox/components/ThemeProvider';

type WithReduxFormState = {
    error: ?string,
    warning: ?string,
    focused: boolean
};

export default <P: *>(Component: React.ComponentType<P>): * => {
    return class WithReduxForm extends React.Component<P, WithReduxFormState> {
        static displayName = `WithReduxForm(${getDisplayName(Component)})`;

        static getDerivedStateFromProps(props: P, state: WithReduxFormState) {
            if (!state.focused) {
                return {
                    warning: props.meta.warning,
                    error: props.meta.error
                };
            }

            return null;
        }

        state = {
            error: null,
            warning: null,
            focused: false
        };

        constructor(...args: *) {
            super(...args);

            // $FlowIgnore
            this.handleBlur = this.handleBlur.bind(this);
            // $FlowIgnore
            this.handleFocus = this.handleFocus.bind(this);
        }

        handleFocus(...args: *) {
            this.setState({ focused: true });
            this.props.input.onFocus(...args);
        }

        handleBlur(...args: *) {
            this.setState({
                warning: this.props.meta.warning,
                error: this.props.meta.error,
                focused: false
            });
            this.props.input.onBlur(...args);
        }

        render() {
            const { input, meta: { touched }, ...props } = this.props;

            return (
                <Component
                    {...input}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    {...props}
                    className="form-field"
                    wrapperClassName="form-field-wrapper"
                    error={touched ? this.state.error : null}
                    warning={touched ? this.state.warning : null}
                />
            );
        }
    };
};

const menuStyles = () => ({
    Menu: {
        '@media (max-width: 800px)': {
            maxWidth: 175
        }
    }
});

export const withDropdownAdapter = <P: *>(Component: React.ComponentType<P>): * => {
    const DropdownAdapter = ({ onChange, onFocus, onBlur, menuClassName, classes, ...props }: P) => (
        <Component
            {...props}
            menuClassName={cx(classes.Menu, menuClassName)}
            onSelect={onChange}
            onToggle={(state) => {
                state ? onFocus() : onBlur();
            }}
        />
    );

    DropdownAdapter.displayName = `DropdownAdapter(${getDisplayName(Component)})`;

    return withStyles(menuStyles)(DropdownAdapter);
};
