export const addressFirst = 'address1';
export const addressSecond = 'address2';
export const addressThird = 'address3';
export const addressFourth = 'address4';
export const contactName = 'publisher.contact_name';
export const country = ['country', 'publisher.operating_country'];
export const entityTermsAndConditions = 'publisher.entity_terms_id';
export const networkTermsAndConditions = 'publisher.network_terms_id';
export const termsAndConditions = 'publisher.terms_and_conditions';
export const promotionalMethod = 'publisher.promotional_method';
export const websiteUrl = 'publisher.websites[0].website_url';
export const websiteType = 'publisher.websites[0].website_type';
export const websiteVertical = 'publisher.websites[0].website_vertical';
export const emailAddress = 'email_address';
export const focusOfContent = 'publisher.vertical';
export const password = 'password';
export const phone = ['phone', 'publisher.phone'];
export const postcode = 'postcode';
export const userName = ['user_name', 'publisher.account_name'];
export const recaptcha = 'captcha';
export const hcaptcha = 'captcha';
export const companyName = 'publisher.company_name';
