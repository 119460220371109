// @flow
import * as api from 'app/api';
import HiddenField from 'app/default/field/HiddenField';
import componentMount from 'app/hoc/componentMount';
import * as React from 'react';
import createField from 'app/hoc/createField';
import l from 'app/Translate';
import { notEmptyValidate, isURL } from 'app/validator/sync';
import { connect } from 'react-redux';
import { websiteUrl, websiteType, websiteVertical} from './name';
import { Input } from './base';

const WebsiteUrlField = createField(websiteUrl, () => ({
    id: 'WebsiteUrl',
    type: 'text',
    label: l('Website URL'),
    placeholder: l('Enter'),
    validate: isURL({
        protocols: ['http', 'https'],
        require_protocol: true
    }),
    warn: notEmptyValidate,
    tooltip: l('Web Protocol is required, please include https:// or http:// at the front of your website. Example: https://partnerize.com')
}))(Input);

const getDefaultVerticalId = (verticals: Array<{ name: string, value: string }>) => {
    let found = verticals.find(({ name }) => name.toLowerCase() === 'other');
    if (found) {
        return found.value;
    }

    found = verticals.find(({ name }) => name.toLowerCase() === 'generic');
    if (found) {
        return found.value;
    }

    found = verticals.pop();
    if (found) {
        return found.value;
    }

    return null;
};

const WebsiteVerticalField = connect(
    (state) => {
        return ({
            name: websiteVertical,
            value: getDefaultVerticalId(api.vertical.selectors.selectData(state) || []),
        });
    },
    (dispatch) => ({
        onMountLoadAction() {
            dispatch(api.vertical.actionCreators.fetchAction());
        }
    })
)(componentMount(HiddenField));

const WebsiteTypeField = connect(
    (state) => {
        const found = (api.websiteType.selectors.selectData(state) || []).find(({ name }) => name.toLowerCase() === 'other');
        return ({
            name: websiteType,
            value: found ? found.website_id : null,
        });
    },
    (dispatch) => ({
        onMountLoadAction() {
            dispatch(api.websiteType.actionCreators.fetchAction());
        }
    })
)(componentMount(HiddenField));

const WebsiteField = () => (
    <React.Fragment>
        <WebsiteUrlField />
        <WebsiteTypeField />
        <WebsiteVerticalField />
    </React.Fragment>
);

export default WebsiteField;
