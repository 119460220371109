// @flow
import { withStyles } from '@phg/stilo-toolbox/components';
import createField from 'app/hoc/createField';
import l  from 'app/Translate';
import { Input } from './base';
import { addressFourth } from './name';

const makeStyle = () => ({
    OptionalHelper: {
        position: 'absolute'
    },
    Optional: {
        fontStyle: 'italic',
        position: 'absolute',
        top: 0,
        left: 165,
        lineHeight: '31px'
    }
});

const AddressFourthField = createField(addressFourth, () => ({
    id: 'AddressFourth',
    type: 'text',
    label: l('Address 4'),
    placeholder: l('Enter'),
    optional: true,
    optionalLabel: l('Optional')
}))(withStyles(makeStyle)(Input));

export default AddressFourthField;
