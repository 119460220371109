// @flow 
import * as React from 'react';
import { createSelector } from 'reselect';
import { getFormAsyncErrors, getFormSyncErrors, getFormSyncWarnings, hasSubmitFailed, submit } from 'redux-form';
import getIn from 'redux-form/lib/structure/plain/getIn';
import remoteMeta from 'app/state/ducks/remoteMeta';
import { withStepperControl } from 'app/components/Stepper';
import connectWithFormName from './withFormName';

export type ButtonDerivedProps = {
    loading: boolean,
    hasErrors: boolean,
    submitFailed: boolean,
    handleBack: () => void,
    handleNext: () => void,
    handleSubmit: () => void
};

const selectRegisteredFieldNames = createSelector(
    (state, { formName }) => {
        return state.form[ formName ] ? state.form[ formName ].registeredFields : [];
    },
    (registeredFields) => {
        return Object.values(registeredFields).filter(({ count }) => count > 0).map(({ name }) => name);
    }
); 

const selectHasErrors = createSelector(
    (state, { formName }) => getFormSyncErrors(formName)(state),
    (state, { formName }) => getFormAsyncErrors(formName)(state),
    (state, { formName }) => getFormSyncWarnings(formName)(state),
    selectRegisteredFieldNames,
    (syncErrors, asyncErrors, syncWarnings, registeredFields) => {
        for (const field of registeredFields) {
            for (const bucket of [syncWarnings, syncErrors, asyncErrors]) {
                if (getIn(bucket, field)) {
                    return true;
                }
            }
        }
        
        return false;
    }
);

const createButton = (Component: React.ComponentType<*>) => {
    const withForm = connectWithFormName(
        (state, { formName }) => ({
            loading: remoteMeta.selectors.hasPendingRquests(state) || remoteMeta.selectors.hasPendingValidations(state),
            hasErrors: selectHasErrors(state, { formName }),
            submitFailed: hasSubmitFailed(formName)(state),
        }),
        (dispatch, { formName, stepperControl }) => ({
            handleSubmit(event: SyntheticInputEvent<HTMLInputElement>) {
                if (event) {
                    event.stopPropagation();
                    event.preventDefault();
                }

                dispatch(submit(formName));

                return false;
            },
            handleBack(event: SyntheticInputEvent<HTMLInputElement>) {
                if (event) {
                    event.stopPropagation();
                    event.preventDefault();
                }

                stepperControl.prev();

                return false;
            },
            handleNext(event: SyntheticInputEvent<HTMLInputElement>) {
                if (event) {
                    event.stopPropagation();
                    event.preventDefault();
                }

                stepperControl.next();

                return false;
            },
        }),
    )(Component);
    
    return withStepperControl(withForm);
};

export default createButton;