// @flow
import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { formValues } from 'redux-form';
import {InfoBox} from '@phg/stilo-toolbox/components/InfoBox';

import CheckboxComponent from 'app/components/Form/checkbox';
import withFeedback from 'app/hoc/withFeedback';
import componentMount from 'app/hoc/componentMount';
import createField from 'app/hoc/createField';
import { notEmptyValidate } from 'app/validator/sync';
import { findTerms } from 'app/helpers/language';
import l from 'app/Translate';
import * as api from 'app/api';
import { entityTermsAndConditions, country } from './name';

import type { Dispatch } from 'redux';
import type { InputProps } from 'redux-form/lib/FieldProps.types';

type TermsAndConditionsBaseProps = {
    input: InputProps,
    label: React.Node,
    checkedValue: mixed,
    id: string,
    head?: React.Node,
    children?: React.Node,
    loadTerms: ({ countryIso: string, locale: string, networkId: string }) => void,
    countryIso: string,
    locale: string,
    networkId: string
};

class TermsAndConditionsBase extends React.Component<TermsAndConditionsBaseProps> {
    componentDidUpdate() {
        this.props.loadTerms(this.props);
    }

    render() {
        return (
            <div className="row">
                <InfoBox>
                    {this.props.head}
                    <CheckboxComponent
                        checkedValue={this.props.checkedValue}
                        uncheckedValue=""
                        id={this.props.id}
                        input={this.props.input}
                        style={{ marginTop: '-5px' }}
                        label={<span>{this.props.label}</span>}
                    />
                    {this.props.children}
                </InfoBox>
            </div>
        );
    }
}

TermsAndConditionsBase.propTypes = {
    id: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    label: PropTypes.node.isRequired,
    checkedValue: PropTypes.string.isRequired,
    children: PropTypes.node
};

export const TermsAndConditionsRow = withFeedback(TermsAndConditionsBase);

const TermsAndConditionsField = createField(entityTermsAndConditions, () => ({
    id: 'TermsAndConditions',
    validate: notEmptyValidate,
    label: <span>{l('Accept Terms and Conditions')}</span>
}))(TermsAndConditionsRow);

export const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
    loadTerms({ countryIso, locale, networkId }: *) {
        dispatch(api.terms.actionCreators.fetchAction(locale, countryIso, networkId));
    }
});

export const entityTermsSelector = createSelector(
    api.terms.selectors.selectData,
    api.locale.selectors.selectCurrentLanguage,
    (terms, locale) => {
        if (terms) {
            return findTerms(terms, 'entity', locale);
        }

        return {};
    }
);

const ConnectedTermsAndConditionsField = connect(
    (state) => ({
        locale: api.locale.selectors.selectCurrentLanguage(state),
        checkedValue: String(entityTermsSelector(state).ref_terms_and_conditions_locale_id),
    }),
    mapDispatchToProps
)(componentMount(TermsAndConditionsField));

ConnectedTermsAndConditionsField.propTypes = {
    networkId: PropTypes.string.isRequired
};

export default formValues({ countryIso: country[0] })(ConnectedTermsAndConditionsField);
