// @flow
import * as React from 'react';
import PropTypes from 'prop-types';
import getDisplayName from 'react-display-name';
import type { HistoryType, LocationType, DisposerSig } from 'app/state/history';

type WithHistoryProps = {
    render?: (LocationType) => React.Node,
    children?: React.Element<React.ComponentType<{ location: LocationType }>>,
    history: HistoryType
};

const historyContextType = {
    _history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired
    }).isRequired
};

export default
class WithHistory extends React.Component<WithHistoryProps, LocationType> {
    static propTypes = {
        render: PropTypes.func,
        children: PropTypes.element,
        history: PropTypes.object
    };

    static childContextTypes = historyContextType;

    _disposer: ?DisposerSig = null;
    
    state: LocationType = this.props.history.location;

    getChildContext = () => ({ _history: this.props.history });
    
    componentDidMount() {
        this._disposer = this.props.history.listen((location) => this.setState(location));
    }

    componentWillUnmount() {
        this._disposer && this._disposer();
    }

    componentWillReceiveProps(props: WithHistoryProps) {
        if (this.props.history !== props.history) {
            throw new Error('You better no change history in runtime!');
        }
    }

    render() {
        if (this.props.render) {
            return this.props.render(this.state);
        } else if (this.props.children) {
            return React.cloneElement(this.props.children, { location: this.state });
        }

        throw new Error('At least one of render prop or children must bhe specified!');
    }
}

export const withHistoryControl = (Component: React.ComponentType<*>) => {
    // eslint-disable-next-line react/no-multi-comp
    return class extends React.Component<*> {
        static displayName = `WithHistoryControl(${getDisplayName(Component)})`;
        static contextTypes = historyContextType;

        _disposer: ?DisposerSig = null;
        
        componentDidMount() {
            this._disposer = this.context._history.listen(() => this.forceUpdate());
        }

        componentWillUnmount() {
            this._disposer && this._disposer();
        }
        
        render() {
            return <Component {...this.props} history={this.context._history} />;
        }
    };
};
