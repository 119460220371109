// @flow
import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { formValues } from 'redux-form';
import { Link } from '@phg/stilo-toolbox/v2/component';
import { deepEquals } from '@phg/stilo-toolbox/utils';

import createField from 'app/hoc/createField';
import { notEmptyValidate } from 'app/validator/sync';
import ModalTrigger from 'app/generic/component/ModalTrigger';
import componentMount from 'app/hoc/componentMount';
import { findTerms } from 'app/helpers/language';
import l from 'app/Translate';
import * as api from 'app/api';

import Checkbox from '../component/Checkbox';
import { country, entityTermsAndConditions, networkTermsAndConditions, termsAndConditions } from './name';

import type { Dispatch } from 'redux';
import type { InputProps } from 'redux-form/lib/FieldProps.types';

type FieldPropSetType = {
    input: InputProps,
    meta: {
        form: string,
        dispatch: Dispatch<*>
    }
};


type TermsType = {
    id: string | number,
    content: string
};

type TermsAndConditionsBaseProps = {
    id: string,
    loadTerms: ({ countryIso: string, locale: string, networkId: string }) => void,
    countryIso: string,
    locale: string,
    networkId: string,
    terms: {
        entity: TermsType,
        network: TermsType
    },
    entityTermsAndConditions: FieldPropSetType,
    networkTermsAndConditions: FieldPropSetType,
    termsAndConditions: FieldPropSetType
};

const extractTermsContent = terms => terms && terms.content;

export
class TermsAndConditionsField extends React.Component<TermsAndConditionsBaseProps, *> {
    componentDidUpdate() {
        this.props.loadTerms(this.props);
    }

    state = {
        checked: true
    };

    static getDerivedStateFromProps(props: TermsAndConditionsBaseProps, state: *) {
        const checked = [
            props.terms.entity ? { entity: props.terms.entity.id } : false,
            props.terms.network ? { network: props.terms.network.id } : false
        ].filter(Boolean);

        if (!deepEquals(checked, state.checked)) {
            return {
                checked
            };
        }

        return null;
    }

    // eslint-disable-next-line complexity
    callEventHandler(handler: string, eventOrValue: SyntheticInputEvent<HTMLInputElement> | string) {
        const checked = (typeof eventOrValue === 'object' && 'target' in eventOrValue ? eventOrValue.target.value : eventOrValue) !== 'off';

        const { entityTermsAndConditions, networkTermsAndConditions, termsAndConditions, terms } = this.props;

        if (terms.entity) {
            entityTermsAndConditions.input[handler](checked ? terms.entity.id: '');
        }

        if (terms.network) {
            networkTermsAndConditions.input[handler](checked ? terms.network.id : '');
        }

        termsAndConditions.input[handler](checked ? this.state.checked : '');
    }

    render() {
        const { id, terms, termsAndConditions: { input } } = this.props;
        return (
            <Checkbox
                checkedValue="on"
                uncheckedValue="off"
                id={id}
                input={{
                    ...input,
                    value: deepEquals(input.value || [], this.state.checked) ? 'on' : 'off',
                    onBlur: this.callEventHandler.bind(this, 'onBlur'),
                    onChange: this.callEventHandler.bind(this, 'onChange')
                }}
                label={
                    <React.Fragment>
                        <ModalTrigger
                            id={`${id}-modal`}
                            title={l('Terms & conditions')}
                            content={Object.values(terms).map(extractTermsContent).join('<br /><br />')}
                        >
                            <Link
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    e.nativeEvent.stopImmediatePropagation();
                                }}
                            >
                                {l('Accept terms & conditions')}
                            </Link>
                        </ModalTrigger>
                    </React.Fragment>
                }
            />
        );
    }
}

export const entityTermsSelector = createSelector(
    api.terms.selectors.selectData,
    api.locale.selectors.selectCurrentLanguage,
    (terms, locale) => {
        if (terms) {
            const entity = findTerms(terms, 'entity', locale);
            const network = findTerms(terms, 'network', locale);

            return {
                entity: entity.ref_terms_and_conditions_locale_id ? {
                    id: entity.ref_terms_and_conditions_locale_id,
                    content: entity.terms_and_conditions
                } : undefined,

                network: network.ref_terms_and_conditions_locale_id ? {
                    id: network.ref_terms_and_conditions_locale_id,
                    content: network.terms_and_conditions
                } : undefined
            };
        }

        return {};
    }
);

const ConnectedTermsAndConditionsField = connect(
    (state) => ({
        locale: api.locale.selectors.selectCurrentLanguage(state),
        terms: entityTermsSelector(state)
    }),
    (dispatch: Dispatch<*>) => ({
        loadTerms({ countryIso, locale, networkId }: *) {
            dispatch(api.terms.actionCreators.fetchAction(locale, countryIso, networkId));
        }
    })
)(componentMount(TermsAndConditionsField));

ConnectedTermsAndConditionsField.propTypes = {
    networkId: PropTypes.string.isRequired
};

const Field = formValues({ countryIso: country[0] })(ConnectedTermsAndConditionsField);

export default createField({
    entityTermsAndConditions,
    networkTermsAndConditions,
    termsAndConditions
}, () => ({
    id: 'TermsAndConditions',
    warn: {
        termsAndConditions: notEmptyValidate
    }
}))(Field);
