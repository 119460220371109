// @flow
import * as React from 'react';
import PropTypes from 'prop-types';
import getDisplayName from 'react-display-name';
import { filterProps } from '@phg/stilo-utils/Objects';


const filterWithDataWrapperProps = filterProps(['onMountLoadAction']);

type OnMountLoadActionSig<P> = (P) => void;

type ComponentMountWrapperProps<P> = {|
    onMountLoadAction: OnMountLoadActionSig<P>
|};

export default <C: React.ElementType, P: React.ElementConfig<C>>(Wrapped: C) =>
    class componentMountWrapper extends React.Component<P & ComponentMountWrapperProps<P>> {
        static displayName = `ComponentMountAction(${getDisplayName(Wrapped)})`;
        
        static propTypes = {
            ...Wrapped.propTypes ? Wrapped.propTypes : {},
            onMountLoadAction: PropTypes.func
        };

        componentDidMount = () => {
            if (this.props.onMountLoadAction) {
                this.props.onMountLoadAction(this.props);
            }
        };

        render() {
            return React.createElement(Wrapped, filterWithDataWrapperProps(this.props));
        }
    };
