// @flow
import * as React from 'react';
import { Dropdown } from '@phg/stilo-toolbox/components';
import { buildUrl, parseUrl } from 'app/helpers/url';
import { withHistoryControl } from 'app/components/WithHistory';
import type { HistoryType } from 'app/state/history';

const availableLanguages = {
    'bg': 'Български',
    'cs': 'Čeština',
    'da': 'Dansk',
    'de': 'Deutsch',
    'el': 'Greek',
    'en': 'English (United Kingdom)',
    'en_ca': 'English (Canada)',
    'en_au': 'English (Australia)',
    'en_us': 'English (United States)',
    'es': 'Español',
    'es_mx': 'Español (Mexico)',
    'fi': 'Suomi',
    'fl': 'Liechtenstein',
    'fr': 'Français',
    'fr_ca': 'Français (Canada)',
    'hu': 'Magyar',
    'id': 'Bahasa Indonesia',
    'it': 'Italiano',
    'jp': '日本語',
    'ko_kr': '한국어',
    'lt': 'Lithuanian',
    'lv': 'Latvian',
    'mt': 'Maltese',
    'my': 'Malay',
    'nl': 'Nederlands',
    'no': 'Norsk',
    'pl': 'Polish',
    'pt': 'Português',
    'pt_br': 'Português (Brasil)',
    'ro': 'Limba română',
    'ru': 'Русский',
    'sk': 'Slovak',
    'sl': 'Slovene',
    'sv': 'Svenska',
    'th': 'ไทย',
    'tl': 'Tagalog',
    'tr': 'Türkçe',
    'vi': 'Vietnamese',
    'zh_cn': '中文（简体）',
    'zh_hk': '中文（繁體）'
};

type SelectOptionType = { value: string, name: string };

export function object2SelectOptions(options: { +[K: string]: string }, sort: *): SelectOptionType[] {
    const keys = Object.keys(options).map((key: string): SelectOptionType => {
        return { value: key, name: options[key] };
    });

    if (sort !== null) {
        return keys.sort(sort);
    }

    return keys;
}

type LanguageSelectorProps = {
    history: HistoryType
};

const LanguageSelector = ({ history }: LanguageSelectorProps) => {
    const parsedUrl = parseUrl(history.location.pathname);
    return (
        <Dropdown
            id="LanguageSelector"
            options={object2SelectOptions(availableLanguages)}
            value={parsedUrl.locale}
            onSelect={(language) => {
                window.location = buildUrl(language, parsedUrl.network);
                //history.push(buildUrl(language, parsedUrl.network))
            }}
            returnSingleValue={true}
        />
    );
};

const StyledLanguageSelector = withHistoryControl(LanguageSelector);

export {
    StyledLanguageSelector as default, LanguageSelector
};
