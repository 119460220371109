// @flow
import * as React from 'react';
import { EntypoHelpWithCircle } from 'react-entypo-icons';
import windowSize from 'react-window-size';
import { withStyles } from '@phg/stilo-toolbox/components/ThemeProvider';
import { Popover } from '@phg/stilo-toolbox/v2/component';
import makeStyle from './style';

const MOBILE_VIEW_THRESHOLD = 800;

const WidthAwarePopover = ({ children, windowWidth, windowHeight: _, ...props }: *) => (
    <Popover
        {...props}
        placement={windowWidth >= MOBILE_VIEW_THRESHOLD ? 'right' : 'left'}
    >
        {children}
    </Popover>
);

const WrappedPopover = windowSize(WidthAwarePopover);

const PopoverWrapper = ({ classes, id, onMouseOver, onMouseOut, children, isOpen, tooltip }: *) => {
    const icon = (
        <EntypoHelpWithCircle
            id={`${id}-help`}
            className={classes.Icon}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
        />
    );

    return (
        <div className={classes.Wrapper}>
            {children}
            <WrappedPopover
                id={`${id}-tooltip`}
                anchor={icon}
                open={isOpen}
            >
                {tooltip}
            </WrappedPopover>
        </div>
    );
};

export default withStyles(makeStyle)(PopoverWrapper);