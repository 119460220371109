// @flow
import * as React from 'react';
import HiddenField from 'app/default/field/HiddenField';


type MerchantFieldProps = {
    merchantId?: string
};

const MerchantField = ({ merchantId }: MerchantFieldProps) => <HiddenField name="publisher.reporting_identifier" value={merchantId} />;

export default MerchantField;
