//@flow
import { makeReadOnlyApi } from 'app/api/apiFactory';
import { sortOptions } from 'app/helpers/object';

import type { Dispatch } from 'redux';

const fetchAsync = (api) => (dispatch: Dispatch<*>, getState: () => *, { client }) => {
    return client({
        dispatch,
        url: '/api/reference/vertical',
        responseHandler(response) {
            const verticals = response.data.verticals.map(({ vertical: { vertical_id: value, name } }) => ({ name, value: String(value) }));

            verticals.sort(sortOptions);

            dispatch(api.actionCreators.successAction(verticals));
        },
    });
};

export default makeReadOnlyApi('vertical', {
    actionCreatorsFactory: ({ FETCH_ASYNC }) => ({
        fetchAction() {
            return ({
                type: FETCH_ASYNC,
                payload: { actionCreator: fetchAsync },
                meta: { remote: { ttl: Infinity, concurrent: false }, api: this }
            });
        }
    })
});


