export const replaceInPath = (subject = {}, path = [], value = null) => {
    if (typeof path === 'string') {
        path = path.split('.');
    }

    if (path.length === 0) {
        return value;
    }

    const key = path.shift();
    return {
        ...subject,
        [key]: replaceInPath(subject[key] || {}, path, value)
    };
};

export const setInPath = (subject = {}, path = [], value = null) => {
    if (typeof path === 'string') {
        path = path.split('.');
    }

    if (path.length === 0) {
        return value;
    }

    const key = path.shift();

    subject[key] = replaceInPath(subject[key] || {}, path, value);

    return subject;
};

export const sortOptions = function (item1, item2) {
    return (item1.name < item2.name) ? -1 : ((item1.name > item2.name) ? 1 : 0);
};

export const arrayDiff = (a, b) => a.filter((i) => !b.includes(i));
