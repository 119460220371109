// @flow
import createField from 'app/hoc/createField';
import {
    notEmptyValidate
} from 'app/validator/sync';
import l from 'app/Translate';
import { Input } from './base';
import { positionTitleName } from './name';

const PositionTitleField = createField(positionTitleName, () => ({
    id: 'PositionTitle',
    type: 'text',
    label: l('Position / Title'),
    warn: notEmptyValidate
}))(Input);
export default PositionTitleField;
