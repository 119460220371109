// @flow
import * as React from 'react';
import cx from 'classname';
import { CheckboxWithLabelHOC } from '@phg/stilo-toolbox/v2/compositions/CheckboxWithLabel';
import { withStyles } from '@phg/stilo-toolbox/components/ThemeProvider/index';

import type { InputProps } from 'redux-form/lib/FieldProps.types';

export type TextFieldPropsType = {
    input: InputProps,
    checkedValue: mixed,
    uncheckedValue: mixed,
    className?: string,
    classes: {
        Label: string
    }
};

const CheckboxComponent = ({
    input: { onFocus, onBlur, value, onChange, ...input },
    checkedValue, uncheckedValue, classes, className, ...props
}: TextFieldPropsType) => (
    <CheckboxWithLabelHOC
        {...props}
        {...input}
        className={cx(classes.Label, className)}
        onChange={(e) => {
            onFocus && onFocus(e);
            if (e.target.checked) {
                onChange(checkedValue);
                onBlur && onBlur(checkedValue);
            } else {
                onChange(uncheckedValue);
                onBlur && onBlur(uncheckedValue);
            }
        }}
        checked={value === checkedValue}
        position="left"
    />
);

CheckboxComponent.defaultProps = {
    checkedValue: true,
    uncheckedValue: false
};

const makeStyles = () => ({
    Label: {
        marginBottom: 0
    }
});

export default withStyles(makeStyles)(CheckboxComponent);
