// @flow

function devLog(log: string, ...styles: string[]) {
    if (process.env.NODE_ENV === 'development') {
        const bg = 'black';
        const text = 'lightgreen';
        // eslint-disable-next-line no-console
        console.log('%cDEV%c ' + log, `border: solid 3px ${bg}; background: ${bg}; color: ${text}; font-weight: bold`, '', ...styles);
    }
}

export { devLog };
