import * as React from 'react';
import PropTypes from 'prop-types';
import {l} from 'app/Translate';
import Button from '@phg/stilo-toolbox/components/button';
import {withStyles} from '@phg/stilo-toolbox/components/ThemeProvider';
import makeStyle from './errorComponentStyles';

const renderField = ({ classes, error }) => {
    return (
        <div className={'col-md-12 ' + classes.ErrorPage}>
            <div className="errorPanel">{error || l('Oops, something went wrong.')}</div>
            <div>
                <Button
                    bsStyle="success"
                    text={l('Reload Page')}
                    type="button"
                    onClick={window.location.reload.bind(window.location)}
                />
            </div>
        </div>
    );
};

renderField.propTypes = {
    classes: PropTypes.object.isRequired,
    error: PropTypes.node,
};

const themedRenderedField = withStyles(makeStyle)(renderField);

export {
    themedRenderedField, themedRenderedField as default
};