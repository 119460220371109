// @flow
import * as React from 'react';
import { FormFeedback } from '@phg/stilo-toolbox/v2/component';
import createField from 'app/hoc/createField';

type FieldErrorProps = {
    meta: {
        warning?: ?string,
        error?: ?string,
        touched: boolean
    }
};

const FieldError = ({ meta: { touched, warning, error } }: FieldErrorProps) => {
    if (touched) {
        if (error) {
            return <FormFeedback kind="error" style={{ margin: 3, textAlign: 'left' }}>{error}</FormFeedback>;
        }

        if (warning) {
            return <FormFeedback kind="warning" style={{ margin: 3, textAlign: 'left', color: '#212322'}}>{warning}</FormFeedback>;
        }
    }

    return null;
};

export default (fields: string | Array<string>) => createField(fields, undefined, true)(FieldError);
