// @flow
import * as React from 'react';
import actions from 'redux-form/lib/actions';
import TextField from 'app/components/Form/textField';
import withFeedback from 'app/hoc/withFeedback';
import createField from 'app/hoc/createField';
import type { Dispatch } from 'redux';

const Input = withFeedback(TextField);

type FieldPropSetType = {
    input: {
        name: string,
        onBlur: (*) => void,
        onChange: (*) => void
    },
    meta: {
        form: string,
        dispatch: Dispatch<*>
    }
};

type WebsiteDataType = {
    url: FieldPropSetType,
    vertical: FieldPropSetType,
    type: FieldPropSetType
};

type WebsiteInputProps = WebsiteDataType & {
    names: { [string]: string },
    websiteVertical: string, 
    websiteType: string
};

class WebsiteInput extends React.Component<WebsiteInputProps> {
    callEventHandler = (handler: string, eventOrValue: SyntheticInputEvent<HTMLInputElement> | string) => {
        const value = typeof eventOrValue === 'object' && 'target' in eventOrValue ? eventOrValue.target.value : eventOrValue;

        const { url, vertical, type } = this.props;

        url.input[handler](value);

        if (value) {
            vertical.input[handler](this.props.websiteVertical);
            type.input[handler](this.props.websiteType);
        } else {
            url.meta.dispatch(actions.change(url.meta.form, url.input.name, '', false, false));
            vertical.meta.dispatch(actions.change(vertical.meta.form, vertical.input.name, '', false, false));
            type.meta.dispatch(actions.change(type.meta.form, type.input.name, '', false, false));
        }
    };

    onChange = (value: SyntheticInputEvent<HTMLInputElement> | string) => this.callEventHandler('onChange', value);
    onBlur = (value: SyntheticInputEvent<HTMLInputElement> | string) => this.callEventHandler('onBlur', value);

    render() {
        // eslint-disable-next-line no-unused-vars
        const { url, vertical, type, ...props } = this.props;
        const { input, ...urlProps } =  url;

        return (
            <Input
                {...props}
                {...urlProps}
                input={{
                    ...input,
                    onChange: this.onChange,
                    onBlur: this.onBlur
                }}
            />
        );
    }
}

const WebsiteField = createField({
    url: 'publisher.websites[0][website_url]',
    type: 'publisher.websites[0][website_type]',
    vertical: 'publisher.websites[0][website_vertical]'
}, () => ({
    id: 'publisher_meta_website_url',
    websiteVertical: '10',
    websiteType: '11'
}))(WebsiteInput); 

export default WebsiteField;
