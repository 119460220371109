// @flow
import createHistory from 'history/createBrowserHistory';

export type LocationType = {
    hash: string,
    key?: string,
    pathname: string,
    search: string,
    state?: {}
};

export type ActionType = 'PUSH' | 'REPLACE' | 'POP';

export type HistoryListenerSig = (location: LocationType, action: ActionType) => void;

export type DisposerSig = () => void;

export type PathType = string | LocationType;

export type HistoryType = {
    action: ActionType,
    block: (?string) => DisposerSig,
    createHref: (LocationType) => string,
    go: (number) => void,
    goBack: () => void,
    goForward: () => void,
    length: number,
    push: (PathType, ?{}) => void,
    replace: (PathType, ?{}) => void,
    location: LocationType,
    listen: (HistoryListenerSig) => DisposerSig
};

const history = createHistory();

export default history;

if (process.env.NODE_ENV === 'development') {
    window.myHistory = history;
}
