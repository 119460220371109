// @flow
import * as React from 'react';
import { StiloModal } from '@phg/stilo-toolbox/components/Modal';
import PropTypes from 'prop-types'; 

type ModalProps = {
    title: React.Node,
    close?: () => void,
    isOpen?: boolean,
    content: string,
    maxHeight?: number
};

const Modal = ({ title, close, isOpen = true, content, maxHeight = 500 }: ModalProps) => (
    <StiloModal
        title={title}
        close={close}
        isOpen={isOpen}
    >
        <p 
            style={{maxHeight, overflow: 'auto'}}
            dangerouslySetInnerHTML={{
                __html: content
            }}
        />
    </StiloModal>
);

Modal.propTypes = {
    title: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    content: PropTypes.string.isRequired,
    maxHeight: PropTypes.number
};

export default Modal;
