// @flow
export default () => ({
    Wrapper: {
        position: 'relative'
    },
    Icon: {
        cursor: 'help',
        position: 'absolute',
        right: -26,
        marginTop: 'auto',
        marginBottom: 'auto',
        top: 0,
        bottom: 0,
        fontSize: 16
    }
});
