// @flow
import * as React from 'react';
import PropTypes from 'prop-types';

export default
class DefaultValueFields extends React.Component<*> {
    static propTypes = {
        defaultValue: PropTypes.string,
        input: PropTypes.shape({
            onBlur: PropTypes.func,
            value: PropTypes.any
        })
    };
    
    componentDidMount() {
        this.componentDidUpdate();
    }
    
    componentDidUpdate() {
        if (this.props.input.value !== this.props.defaultValue) {
            this.props.input.onBlur(this.props.defaultValue);
        }
    }

    render() {
        return null;
    }
}
