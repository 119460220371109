// @flow
import * as React from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';

export default class Hcaptcha extends React.Component<*> {
    componentDidMount() {
        if (this.props.onVerify) {
            this.props.onVerify(null);
        }
    }

    render() {
        // eslint-disable-next-line no-unused-vars
        const { value, wrapperClassName, ...props } = this.props;
        return React.createElement(HCaptcha, props);
    }
}
