// @flow
import * as React from 'react';
import getDisplayName from 'react-display-name';
import PopoverWrapper from 'app/components/PopoverWrapper';


export default <P: *>(Component: React.ComponentType<P>): * => {
    return class WithTetheredTooltip extends React.Component<P & { tooltip?: React.Node }, *> {
        static displayName = `WithTetheredTooltip(${getDisplayName(Component)})`;

        state = {
            open: false
        };

        _closeTimer: ?TimeoutID = null;

        constructor(...args: *) {
            super(...args);

            // $FlowIgnore
            this.handleOpen = this.handleOpen.bind(this);
            // $FlowIgnore
            this.handleClose = this.handleClose.bind(this);
        }

        handleOpen() {
            if (this._closeTimer) {
                clearTimeout(this._closeTimer);
                this._closeTimer = null;
            }

            this.setState({ open: true });
        }

        handleClose() {
            this._closeTimer = setTimeout(this.setState.bind(this), 200, { open: false });
        }

        render() {
            const { tooltip, ...props } = this.props;

            if (!tooltip) {
                return <Component {...props} />;
            }

            return (
                <PopoverWrapper
                    id={props.id}
                    onMouseOver={this.handleOpen}
                    onMouseOut={this.handleClose}
                    isOpen={this.state.open}
                    tooltip={tooltip}
                >
                    <Component {...props} />
                </PopoverWrapper>
            );
        }
    };
};
