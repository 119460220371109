// @flow
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import * as api from 'app/api';
import componentMount from 'app/hoc/componentMount';
import HiddenField from './HiddenField';
import { promotionalMethod } from './name';

const defaultPromotionalMethodSelector = createSelector(
    api.promotionalMethod.selectors.selectData, 
    (options) => {
        if (options) {
            for (const { name, value } of options) {
                if (name.toLowerCase() === 'other') {
                    return value;
                }
            }
            
            throw 'Promotional method with name Other not found';
        }
        
        return null;
    }
);

const DefaultPromotionalMethodField = connect(
    (state) => ({
        name: promotionalMethod,
        value: defaultPromotionalMethodSelector(state)
    }),
    (dispatch) => ({
        onMountLoadAction() {
            dispatch(api.promotionalMethod.actionCreators.fetchAction());
        }
    })
)(componentMount(HiddenField));

export default DefaultPromotionalMethodField;
