// @flow
import * as React from 'react';
import { Field, Fields } from 'redux-form';
import getDisplayName from 'react-display-name';
import createFiledPropsMapper from './createFieldPropsMapper';
import createOneToRuleThemAll from './createOneToRuleThemAll';
import createFieldContextProxy from './createFieldContextProxy';
import { transformObjectKeys } from './utils';
import type { FieldNameType } from './types';

type DefaultPropsType = {+[string]: *};
type DefaultPropsParamType = DefaultPropsType | () => DefaultPropsType;

const translateFields = {
    onDrop: '_onDrop',
    onDragStart: '_onDragStart',
    onFocus: '_onFocus',
    onBlur: '_onBlur',
    onChange: '_onChange',
    onVerify: '_onVerify'
};

const translateMultiFields = {
    warn: '_warn',
    validate: '_validate'
};

const silentlyTransformPropsIfNeeded = <P>(props: P, translateFields: {[string]: string}, transform: boolean): P => {
    // $FlowIgnore - Error is valid, but we want this conversion to be done silently atm.
    return transform ? transformObjectKeys(props, translateFields) : props;
};

export default (names: FieldNameType, defaultProps?: DefaultPropsParamType, onlyTouchedFeedback?: boolean) =>
    <C: React.ComponentType<*>>(Component: C) => {
        let FieldComponent = Field;
        let namePropName = 'name';
        let namePropValue = names;

        let RenderedComponent = null;

        if (typeof names === 'object') {
            FieldComponent = Fields;
            namePropName = 'names';

            if (Array.isArray(names)) {
                RenderedComponent = createOneToRuleThemAll(Component, names, onlyTouchedFeedback);
            } else {
                RenderedComponent = createFiledPropsMapper(Component, names);
                namePropValue = Object.values(names);
            }
        } else {
            RenderedComponent = createFieldContextProxy(Component);
        }

        return class SmartField extends React.Component<React.ElementProps<C>, *> {
            static displayName = `SmartField(${getDisplayName(Component)})`;

            static get defaultProps() {
                if (!defaultProps) {
                    return {};
                }

                return typeof defaultProps === 'function' ? defaultProps() : defaultProps;
            }

            static getDerivedStateFromProps(props: React.ElementProps<C>) {
                return Object.assign(
                    silentlyTransformPropsIfNeeded(
                        silentlyTransformPropsIfNeeded(props, translateFields, true),
                        translateMultiFields, FieldComponent !== Field
                    ),
                    {
                        [namePropName]: namePropValue,
                        component: RenderedComponent
                    }
                );
            }

            state: React.ElementProps<C> = {};

            render() {
                return React.createElement(FieldComponent, this.state);
            }
        };
    };
