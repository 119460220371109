// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import Link from '@phg/stilo-toolbox/components/Links/Link';
import ModalTrigger from 'app/components/ModalTrigger';

import l from 'app/Translate';
import TermsAndConditionsField, { entityTermsSelector } from './TermsAndConditionsField';


type TnCFieldWithModalProps = {
    label: string,
    linkLabel: string,
    modalTitle: string,
    modalContent: string,
    children: React.Node
};

const TermsAndConditionsFieldWithModal = ({ label, linkLabel, modalTitle, modalContent, children, ...props }: TnCFieldWithModalProps) => (
    <TermsAndConditionsField
        {...props}
        label={(
            <span>
                {label}
                {' '}
                {/* $FlowIgnore */}
                <ModalTrigger title={modalTitle} content={modalContent}>
                    {/* $FlowIgnore */}
                    <Link active={false}>{linkLabel}</Link>
                </ModalTrigger>
            </span>
        )}
    >
        {children}
    </TermsAndConditionsField>
);

// $FlowIgnore
Object.defineProperty(TermsAndConditionsFieldWithModal, 'defaultProps', {
    get() {
        return {
            label: l('Accept'),
            linkLabel: l('Terms and Conditions'),
            modalTitle: l('Terms & Conditions'),
        };
    },
});

export default connect(
    (state) => {
        const terms = entityTermsSelector(state);

        return ({
            modalContent: terms.terms_and_conditions || '',
        });
    },
)(TermsAndConditionsFieldWithModal);
