// @flow
import createField from 'app/hoc/createField';
import {
    notEmptyValidate
} from 'app/validator/sync';
import l from 'app/Translate';
import { Input } from './base';
import { companyName } from './name';

const CompanyNameField = createField(companyName, () => ({
    id: 'CompanyName',
    type: 'text',
    label: l('Company name'),
    warn: notEmptyValidate
}))(Input);
export default CompanyNameField;
