// @flow
import { connect } from 'react-redux';
import actions from 'redux-form/lib/actions';
import { createSelector } from 'reselect';
import createField from 'app/hoc/createField';
import { notEmptyValidate, notANumberValidate } from 'app/validator/sync';
import componentMount from 'app/hoc/componentMount';

import * as api from 'app/api';
import l, { translateSelectOptions } from 'app/Translate';
import { Select } from './base';
import { country, entityTermsAndConditions, networkTermsAndConditions, termsAndConditions } from './name';
import { optionsToChildrenSelector } from './utils';

const countriesSelector = optionsToChildrenSelector(
    createSelector(
        api.country.selectors.selectData,
        api.locale.selectors.selectCurrentLanguage,
        translateSelectOptions
    )
);

const CountrySelectField = createField(country, () => ({
    id: 'countrySelect',
    label: l('Country'),
    placeholder: l('Choose...'),
    validate: notANumberValidate,
    warn: notEmptyValidate,
    onChange() {
        [entityTermsAndConditions, networkTermsAndConditions, termsAndConditions]
            .forEach((field) => this.meta.dispatch(actions.change(this.meta.form, field, '', false, false)));
    },
    maxHeight: 250,
    menuClassName: 'countrySelect-menu'
}))(Select);

export default connect(
    (state) => ({
        children: countriesSelector(state)
    }),
    (dispatch) => ({
        onMountLoadAction() {
            dispatch(api.country.actionCreators.fetchAction());
        }
    })
)(componentMount(CountrySelectField));
