//@flow
import axios from 'axios';

const post = (url: string, data: {} = {}, params: {} = {}): * =>
    axios.post(url, data, {
        timeout: 15000,
        ...params
    });

export {
    post as default, post
};
