// @flow
import pathToRegexp from 'path-to-regexp';

const urlTemplate = '/signup/:locale/:network?';
const loginUrlTemplate = ':host/login/:network/:locale?';

const defaultLocale = 'en';
const defaultNetwork = 'partnerize';

export const parseUrl = (pathname: string) => {
    const parsedPath = pathToRegexp('/signup/:locale/:network?', undefined, { endsWith: '?' })
        .exec(pathname);

    return {
        locale: (parsedPath ? parsedPath[1] : defaultLocale),
        network: (parsedPath && parsedPath[2]) || null
    };
};

export const buildUrl = (locale?: ?string, network?: ?string) => {
    return urlTemplate
        .replace(':locale', locale || defaultLocale)
        .replace(':network', network || '')
        .replace(/[/?]+$/, window.location.search);
};

export const buildLoginUrl = (locale: string = defaultLocale, network: string = defaultNetwork) => {
    const devEnvs = [
        'localhost:8081',
        '0.0.0.0:8081',
        '127.0.0.1:8081',
        'phgsite.partnerize.tech'
    ];
    const host = devEnvs.includes(window.location.host)
        ? 'https://phgma.partnerize.tech'
        : 'https://console.partnerize.com';

    return loginUrlTemplate
        .replace(':host', host)
        .replace(':network', network)
        .replace(':locale', locale)
        .replace(/[/?]+$/, window.location.search);
};

export const getUrlParameter = (name: string) => {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};