// @flow
import axios from 'axios';
import { immutableDeepMerge } from '@phg/stilo-toolbox/utils';
import { addError } from 'app/state/reducers/application';

const compileErrorMessageFromHttpResponse = httpError =>
    `${httpError.response.data.error.type}: ${httpError.response.data.error.message} (${httpError.response.statusText})`;

export const handleErrors = ({ httpError = {}, errorFilter, dispatch }: *) => {
    if (process.env.NODE_ENV === 'development') {
        /*eslint no-console: ["error", { allow: ["error"] }] */
        console.error(httpError);
    }

    try {
        if (errorFilter) {
            errorFilter(httpError);
        } else {
            throw httpError;
        }
    } catch (httpError) {
        const errorMessage = httpError.response && httpError.response.data && httpError.response.data.error ?
            compileErrorMessageFromHttpResponse(httpError) :
            httpError.message;

        return dispatch(addError(new Date, errorMessage));
    }
};

export function basePhClient() {
    return ({ url, config = {} }: *) => {
        const basicConfig = {
            timeout: 60000,
            headers: {
                'Content-Type': 'application/json',
                'Pragma': 'no-cache',
            },
            url
        };

        return axios(immutableDeepMerge(basicConfig, config));
    };
}

const checkProps = ({ dispatch, responseHandler }) => {
    if (!dispatch) {
        throw Error('Client called without dispatch parameter');
    }

    if (!responseHandler) {
        throw Error('Client called without responseHandler parameter');
    }
};

export default function phClient() {
    const client = basePhClient();

    return ({ url, config = {}, errorFilter, dispatch, responseHandler }: *) => {
        checkProps({ dispatch, responseHandler });

        return client({ url, config })
            .then(responseHandler)
            .catch(httpError => handleErrors({ httpError, errorFilter, dispatch }));
    };
}
