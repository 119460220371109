// @flow
import * as React from 'react';
import l from 'app/Translate';
import { Recaptcha, Hcaptcha } from 'app/generic/field';
import TermsAndConditionsContent from 'app/default/components/TermsAndConditionsContent';
import { TermsAndConditionsField } from 'app/default/field';
import Buttons, { NextOnSuccess } from 'app/default/button';

type TermsComponentProps = {
    networkId: string,
    captchaProvider?: string
};

const TermsComponent = ({ networkId, captchaProvider }: TermsComponentProps) => (
    <form>
        <div className="terms-box">
            <div className="header">
                {l('Terms & Conditions')}
            </div>
            <TermsAndConditionsContent networkId={networkId} />
        </div>

        <TermsAndConditionsField networkId={networkId} />

        <Buttons back submit />
        {captchaProvider !== ''
            ? captchaProvider !== 'recaptcha'
                ? (
                    <Hcaptcha />
                ) : (
                    <Recaptcha />
                ) 
            : null}
        <NextOnSuccess />
    </form>
);

export default TermsComponent;
