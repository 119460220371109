export const addressFirst = 'address1';
export const addressSecond = 'address2';
export const addressThird = 'address3';
export const campaignsName = 'publisher.campaigns';
export const contactName = 'publisher.contact_name';
export const country = ['country', 'publisher.operating_country'];
export const entityTermsAndConditions = ['publisher.entity_terms_id', 'publisher.terms_and_conditions[0].entity'];
export const promotionalMethod = 'publisher.promotional_method';
export const emailAddress = 'email_address';
export const focusOfContent = 'publisher.vertical';
export const password = 'password';
export const phone = ['phone', 'publisher.phone'];
export const postcode = 'postcode';
export const userName = ['user_name', 'publisher.account_name'];
export const companyName = 'publisher.company_name';
export const positionTitleName = 'publisher.meta.partner_position_title';
export const captcha = 'captcha';
