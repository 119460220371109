// @flow
import TextField from 'app/components/Form/textField';
import SelectFieldGroup from 'app/components/Form/dropDownField';
import withFeedback from 'app/hoc/withFeedback';
import DefaultValueFields from 'app/components/Form/defaultValue';
import ErrorsComponentWithRetry from 'app/components/errorComponentWithRetry';
import CheckBoxGroup from 'app/components/Form/checkboxgroup';

export const Input = withFeedback(TextField);
export const UsernameInput = withFeedback(TextField, ErrorsComponentWithRetry);
export const Select = withFeedback(SelectFieldGroup);
export const CheckBoxes = withFeedback(CheckBoxGroup);
export const Hidden = DefaultValueFields;
