// @flow
import type { ObjectKeysDictionaryType } from './types';

export const transformObjectKeys = <O: {}>(obj: $ReadOnly<O>, dict: ObjectKeysDictionaryType) => {
    return Object.entries(obj).reduce((result, [key, value]) => {
        if (key in dict) {
            key = dict[key];
        }
        
        result[key] = value;
        
        return result;
    }, {});
};

export const bindThisContext = <P: {}>(props: P, thisArg: *, whitelist: Array<string> | boolean = false): $ObjMap<P, <V>(V) => V> => {
    return Object.entries(props).reduce((acc, [key, value]) => {
        if (!Array.isArray(whitelist) || whitelist.includes(key)) {
            acc[key] = value.bind(thisArg); 
        } else {
            acc[key] = value;
        }
        
        return acc;
    }, {});
};
