import Mixpanel from 'mixpanel';
import {delay} from 'redux-saga';
import {FOCUS, BLUR, UPDATE_SYNC_WARNINGS, UPDATE_SYNC_ERRORS} from 'redux-form/lib/actionTypes';
import {STEPPER_GO_BACK, STEPPER_GO_FORWARD} from '@phg/stilo-toolbox/components/Stepper/redux/stepperActions';
import Fingerprint2 from 'fingerprintjs2';
import {takeEvery, select, race, call, take} from 'redux-saga/effects';

export const hasValue = (value: number | string | boolean) => {
    return typeof value === 'boolean' || typeof value === 'number' || (typeof value === 'string' && value.length > 0);
};

export const fieldFocus = (mixpanel, uuid, action) => {
    mixpanel.track('Field Focus', {
        distinct_id: uuid,
        field: action.meta.field
    });
};

export const selector = (state, formName) => state.form[formName] || {};

export function* fieldBlur(mixpanel, uuid, action) {
    yield race({
        errors: take(UPDATE_SYNC_ERRORS),
        warning: take(UPDATE_SYNC_WARNINGS),
        timeout: call(delay, 500)
    });

    const form = yield select(selector, action.meta.form);
    const fieldName = action.meta.field;

    let trackedData = {
        distinct_id: uuid,
        field: action.meta.field,
        hasData: hasValue(action.payload) ? 'yes' : 'no'
    };

    if (form.syncWarnings && form.syncWarnings[fieldName]) {
        trackedData = {
            ...trackedData,
            warning: form.syncWarnings[fieldName]
        };
    } else if (form.syncErrors && form.syncErrors[fieldName]) {
        trackedData = {
            ...trackedData,
            error: form.syncErrors[fieldName]
        };
    }
    mixpanel.track('Field Blur', trackedData);
}

export const formNext = (mixpanel, uuid) => {
    mixpanel.track('Moved to next form step', {
        distinct_id: uuid
    });
};

export const formBack = (mixpanel, uuid) => {
    mixpanel.track('Moved to previous form step', {
        distinct_id: uuid
    });
};

export const formSend = (mixpanel, uuid) => {
    mixpanel.track('Sign Up Clicked', {
        distinct_id: uuid
    });
};

export const pageLoad = (mixpanel, uuid) => {
    mixpanel.track('User entered new publisher signup page', {
        distinct_id: uuid
    });
};

export function checkWarnsAndErrors(mixpanel, uuid, action) {
    const data = action.payload;
    mixpanel.track('Couldn\'t proceed to next step because of warnings', {
        distinct_id: uuid,
        warnings: data
    });
}
export const mixpanel = window.Config && window.Config.mixpanelToken && window.Config.mixpanelToken.length > 0
    ? Mixpanel.init(window.Config.mixpanelToken, {protocol: 'https'})
    : null;

export function getUuid() {
    return new Promise((res) => {
        new Fingerprint2().get(function (result) {
            return res(result);
        });
    });
}

function* track() {
    if (mixpanel) {
        const uuid = yield call(getUuid);
        yield takeEvery(FOCUS, fieldFocus, mixpanel, uuid);
        yield takeEvery(BLUR, fieldBlur, mixpanel, uuid);
        yield takeEvery(STEPPER_GO_FORWARD, formNext, mixpanel, uuid);
        yield takeEvery(STEPPER_GO_BACK, formBack, mixpanel, uuid);
        yield takeEvery('SEND_FORM', formSend, mixpanel, uuid);
        yield takeEvery('APPLICATION_LOADED', pageLoad, mixpanel, uuid);
        yield takeEvery('SUBMIT_FAILED_BECAUSE_OF_WARNINGS', checkWarnsAndErrors, mixpanel, uuid);
    }
}

export {
    track as default
};
