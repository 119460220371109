import logo from 'assets/img/network/appleservices/logo.svg';

const networkStyleMapOverwrite = {
    '$logoImg': logo,
    '$btnColor1':'#f6f6f6',
    '$btnColor2':'#f1f1f1',
    '$enabledSubmitBtn':'#2787fb',
    '$disabledSubmitBtn':'#7cb7fc',
    '$topBarHeight':'55px',
    '$signUpFormWidth':'440px',
    '$logoWidth':'27px',
    '$logoHeight':'40px',
    '$logoMargin':'initial',
    '$brand-success' : '#4FD300',
    '$error-text': 'red',
    '$footerHeight': '40px'
};

export default networkStyleMapOverwrite;
