// @flow
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import createField from 'app/hoc/createField';
import {
    notEmptyValidate
} from 'app/validator/sync';
import componentMount from 'app/hoc/componentMount';

import * as api from 'app/api';
import l, { translateSelectOptions } from 'app/Translate';
import { Select } from './base';
import { focusOfContent } from './name';
import { optionsToChildrenSelector } from './utils';

const verticalsSelector = optionsToChildrenSelector(
    createSelector(
        api.vertical.selectors.selectData,
        api.locale.selectors.selectCurrentLanguage,
        translateSelectOptions
    )
);

const FocusOfContentSelectField = createField(focusOfContent, () => ({
    id: 'focus-select',
    label: l('Vertical'),
    placeholder: l('Choose...'),
    warn: notEmptyValidate,
    maxHeight: 250,
    menuClassName: 'focus-select-menu'
}))(Select);

export default connect(
    (state) => ({
        children: verticalsSelector(state)
    }),
    (dispatch) => ({
        onMountLoadAction() {
            dispatch(api.vertical.actionCreators.fetchAction());
        }
    })
)(componentMount(FocusOfContentSelectField));
