// @flow
import createField from 'app/hoc/createField';
import {
    notEmptyValidate,
    notANumberValidate
} from 'app/validator/sync';
import l from 'app/Translate';
import { Input } from './base';
import { contactName } from './name';

const ContactNameField = createField(contactName, () => ({
    id: 'ContactName',
    type: 'contact',
    label: l('Contact Name'),
    validate: notANumberValidate,
    warn: notEmptyValidate
}))(Input);
export default ContactNameField;
