// @flow
import * as React from 'react';

const TopBar = ({className, children}: { className: string, children?: React.Node }) => (
    <div className={className}>
        <div className="inner">
            <div className="logo"/>
            <div className="children">
                {children}
            </div>
        </div>
    </div>
);

export {
    TopBar as default, 
    TopBar
};