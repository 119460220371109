// @flow
export { default as country } from './endpoint/country';
export { default as vertical } from './endpoint/vertical';
export { default as websiteType } from './endpoint/websiteType';
export { default as promotionalMethod } from './endpoint/promotionalMethod';
export { default as campaign } from './endpoint/campaign';
export { default as locale } from './endpoint/locale';
export { default as terms } from './endpoint/terms';

if (process.env.NODE_ENV === 'development') {
    window.api = module.exports;
}
