// @flow
import createField from 'app/hoc/createField';
import l  from 'app/Translate';
import { Input } from './base';
import { addressThird } from './name';

const AddressThirdField = createField(addressThird, () => ({
    id: 'AddressThird',
    type: 'text',
    label: l('Business address line 3')
}))(Input);

export default AddressThirdField;
