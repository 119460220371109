import validator from 'validator';
import l from 'app/Translate';

export const notEmptyValidate = value => value ? undefined : true;

export const emailValidate = value => (value && !validator.isEmail(value)) ? l('Invalid email address') : undefined;

export const sameFieldValidate = (fieldName, errorMessage) => (value, field, store) =>
    ( value && value !== store.values[fieldName] ) ? errorMessage : undefined;

export const minLengthValidate = min => value => ( value && value.length <= min ) ? l('Field length must be greater than ') + min : undefined;

export const maxLengthValidate = max => value => ( value && value.length > max ) ? l('Field length must not be greater than ') + max : undefined;

export const alphaDashValidate = value =>
    ( value && !validator.matches(value, /^[-a-z0-9_]+$/i) ) ? l('Field can only contain alphanumeric characters') : undefined;

export const notANumberValidate = (value) => ( value && validator.matches(value, /[0-9]+/)) ? l('Field can not contain numbers') : undefined;

/*eslint-disable max-len, no-useless-escape, complexity */
export const passwordValidate = (value) =>
    ( value && ( value.length < 8 || !validator.matches(value, /[a-z]+/) || !validator.matches(value, /[A-Z]+/) || !validator.matches(value, /[0-9]+/) || validator.matches(value, /password/i)
        || !validator.matches(value, /[!"#\$%&'\(\)\*\+,-\.\/:;<=>\?@\[\]\^_`{\|}~¡¢£¤¥¦§¨©ª«¬®¯°±²³´µ¶·¸¹º»¼½¾¿ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿĀāĂăĄąĆćĈĉĊċČčĎďĐđĒēĔĕĖėĘęĚěĜĝĞğĠġĢģĤĥĦħĨĩĪīĬĭĮįİıĲĳĴĵĶķĸĹĺĻļĽľĿŀŁłŃńŅņŇňŉŊŋŌōŎŏŐőŒœŔŕŖŗŘřŚśŜŝŞşŠšŢţŤťŦŧŨũŪūŬŭŮůŰűŲųŴŵŶŷŸŹźŻżŽž]+/) ) ) ?
        l('Your password must be at least 8 characters, contain an upper and lower case character as well as a special character and a number')
        : undefined;
/*eslint-enable max-len, no-useless-escape, complexity */

export const notEmptyArrayValidate = (message = l('Select at least one')) =>
    (value) => (Array.isArray(value) && value.length > 0) ? undefined : message;

export const allTermsAgreed = (terms) => {
    terms = terms.map((t) => JSON.stringify(t.value));
    return (value = []) => {
        value = value.map((val) => JSON.stringify(val));
        for (const term of terms) {
            if (value.indexOf(term) === -1) {
                return l('You must agree to all terms');
            }
        }

        return undefined;
    };
};

export const isURL = (options) => (url) => {
    if (url !== undefined && !validator.isURL(url, options)) {
        return l('Please input a valid URL');
    }
    return undefined;
};

export const deselectOnNotSure = (value, previousValue) => {
    if (value.includes('Not sure')) {
        if (previousValue && previousValue.includes('Not sure')) {

            value = JSON.parse(value);
            const arr = value.filter(item => item != 'Not sure');
            return JSON.stringify(arr);
        }
        return JSON.stringify(['Not sure']);
    }
    return value;
};

export const validateAtLeastOneCampaign = (campaigns) => {
    if (!campaigns || campaigns.length < 1) {
        return l('You must select at least 1 campaign');
    }
};

export const validateCampaigns = (campaigns) => {
    for (const campaign of campaigns || []) {
        if (campaign.terms_id === false) {
            return l('You must complete this field');
        }
    }
};
