// @flow
import * as React from 'react';
import { EntypoHelpWithCircle } from 'react-entypo-icons';
import { notEmptyValidate } from 'app/validator/sync';
import createField from 'app/hoc/createField';
import l from 'app/Translate';
import actions from 'redux-form/lib/actions';
import { OverlayTrigger, Tooltip } from '@phg/stilo-toolbox/components';
import { SelectFieldGroup, strigifiedArray } from 'app/components/Form/dropDownField';
import withFeedback from 'app/hoc/withFeedback';
import { metaVerticalOther, metaPartnerCategory, metaPartnerContentFocus, metaPartnerToolsFocus, metaPartnerCategoryVertical } from './name';

const Select = withFeedback(strigifiedArray(SelectFieldGroup));

const OverlayTriggerContent = () => {
    const tooltipWrapper = (
        <Tooltip id="tooltip-category">
            <div className="tooltip-itunes">
                <div className="h">{l('Website')}</div>
                <div>{l('If you own and operate a verifiable website domain, blog site, are a service provider, or content owner.')}</div>
                <div className="space" />
                <div className="h">{l('App')}</div>
                <div>{l('If you are an iOS app developer with a confirmed App Store URL.')}</div>
            </div>
        </Tooltip>
    );
    
    return (
        <OverlayTrigger placement="bottom" id="tooltipoverlay-category" overlay={tooltipWrapper}>
            <span className="tooltip-trigger">
                <EntypoHelpWithCircle />
            </span>
        </OverlayTrigger>
    );
};

const PartnerCategoryField = createField(metaPartnerCategory, () => ({
    id: 'publisher_meta_partner_category',
    label: <span className="category-label">{l('Category')} <OverlayTriggerContent /></span>,
    options: [
        { name: l('Website'), value: 'Website' },
        { name: l('App'), value: 'App' }
    ],
    warn: (value) => notEmptyValidate(value && JSON.parse(value)[0]),
    onChange() {
        [
            metaVerticalOther,
            metaPartnerContentFocus,
            metaPartnerToolsFocus,
            metaPartnerCategoryVertical
        ].forEach((field) => {
            this.meta.dispatch(actions.change(this.meta.form, field, '', false, false));
            this.meta.dispatch(actions.untouch(this.meta.form, field));
        });
    }
}))(Select);

export default PartnerCategoryField;
