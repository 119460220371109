// @flow
import { reduxForm, SubmissionError } from 'redux-form';
import submitData from 'app/connect/submit';
import asyncValidate, { asyncValidateFields } from 'app/validator/async';

const createSubmitMiddleware = (onSubmit) => (values, dispatch, formProps, ...rest) => {
    if (Object.keys(formProps.syncWarnings).length) {
        dispatch({type: 'SUBMIT_FAILED_BECAUSE_OF_WARNINGS', payload: formProps.syncWarnings});
        throw new SubmissionError({'@hiddenError': 'error'});
    }

    dispatch(formProps.clearSubmitErrors());
    return onSubmit(values, dispatch, formProps, ...rest);
};

export default reduxForm({
    form: 'signupForm',
    asyncValidate,
    asyncBlurFields: asyncValidateFields,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmit: createSubmitMiddleware(submitData)
});
